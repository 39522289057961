.flex-tree {
    ul {
        display: flex;
        list-style: none;
        padding-left: 0;
        text-align: center;

        >li {
            box-sizing: border-box;
            flex: 1;
            padding: 0 5px;

            // We'll accept any container that's not a `ul` or `li` because that would just be silly
            >*:not(ul):not(li) {
                display: inline-block;
                margin: 0 auto;
                position: relative;

                // We want to add a connector on the bottom of the element unless we've reached the bottom of the tree
                // which is signified by :last-child
                &:not(:last-child) {
                    &:after {
                        border-left: 1px solid $color-neutral-06;
                        bottom: -1 * 30px;
                        content: '';
                        height: 30px;
                        left: 50%;
                        position: absolute;
                    }
                }
            }

            // We've reached the children elements
            ul {
                margin-top: (30px * 2) - 1; // Fix 1px spacing between borders, no big deal
                position: relative;

                li {
                    position: relative;

                    // The :before will be the horizontal line on the right side of a child connector and the :after
                    // will be the left side of the child connector. The child connector is the vertical line going
                    // down to its children.
                    &:after,
                    &:before {
                        border-top: 1px solid $color-neutral-06;
                        content: '';
                        position: absolute;
                        top: -1 * 30px;
                        width: 50%;
                    }

                    &:before {
                        border-left: 1px solid $color-neutral-06;
                        height: 30px;
                        left: 50%;
                    }

                    &:after {
                        right: 50%;
                    }

                    // The first and last children won't have to connect to its siblings to the left and right of it,
                    // so handle that appropriately by hiding 
                    &:first-child,
                    &:last-child {
                        &:before {
                            border-top: 1px solid $color-neutral-06;
                            top: -1 * 30px;
                            border-top-left-radius: 24px;
                        }

                        &:after {
                            border: none;
                        }
                    }

                    &:last-child {
                        &:before {
                            border-left: 0;
                            border-right: 1px solid $color-neutral-06;
                            left: 1px;
                            right: 50%;
                            border-top-left-radius: 0;
                            border-top-right-radius: 24px;
                        }
                    }

                    // When it's an only child, there won't be a need to connect to its siblings so just remove the
                    // top border which serves as the horizontal connector
                    &:only-child {
                        &:before {
                            border-top: none;
                            border-top-right-radius: 0;
                        }
                    }
                }
            }
        }
    }

    &__node-group {
        @include flex($fd: column);
        width: 450px;
        @include position ($p: relative);

        &__phrase {
            @include flex($ai: center, $jc: center);
            padding: 15px 0;
            width: 100%;
            text-align: center;
            color: $color-neutral-07;

            b {
                margin-left: 4px;
            }

            .phrase {
                &__condition {
                    @include flex($ai: center, $jc: center);
                    padding: 4px 8px;
                    font-size: 14px;
                    line-height: 18px;
                    background-color: $color-neutral-04;
                    border-radius: 100px;
                    margin-left: 8px;
                    color: $color-neutral-07;

                    //Stats
                    &--focus {
                        border: 2px solid $color-primary-purple-01;
                        outline: 4px solid rgba(119, 86, 219, .20);
                        transition: all .15s ease-in-out;
                        color: $color-primary-purple-01;
                        background-color: transparent;
                    }

                    &--selected {
                        background-color: $color-primary-cherry-pie-01;
                        color: $color-neutral-01;
                    }

                    &--true {
                        background-color: $color-feedback-blue;
                        color: $color-primary-java-03;
                    }

                    &--false {
                        background-color: $color-feedback-orange;
                        color: $color-accent-orange-01;
                    }

                    &--programed {
                        background-color: $color-primary-purple-01;
                        color: $color-neutral-01;
                    }

                    &--error {
                        border: 2px solid $color-primary-carnation-01;
                        outline: 4px solid rgba(119, 86, 219, .20);
                        transition: all .15s ease-in-out;
                        color: $color-primary-carnation-01;
                        background-color: transparent;
                    }
                }
            }
        }
        &__node{
            @include flex($ai: center);
            @include position ($p: relative);
            background-color: $color-neutral-01;
            border-radius: 8px;
            height: 80px;
            padding: 16px;
            width: 450px;
            border: 1px solid $color-neutral-04;
            box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.05), 0px 12px 24px rgba(0, 0, 0, 0.05);
            text-align: left;
            cursor: grab;
            transition: all .15s ease-in-out;
            i[class^='icon-'] {
                @include flex-child($fs: 0);
            }
            .node{
                &__info {
                    @include flex-child($fg: 2);
                    padding: 0 8px 0 24px;
                    &__link {
                        color: $color-neutral-06;
                        padding-top: 4px;
                        font-size: 14px;
                    }
                }
        
                &__actions {
                    @include flex($ai: center);
                    @include flex-child($as: flex-start);
        
                    .tag-icon {
                        border: 1px solid $color-neutral-04;
                        background-color: $color-neutral-01;
                        border-radius: 100px;
                        width: 24px;
                        height: 24px;
        
                        i[class^='icon-'] {
                            color: $color-neutral-01;
        
                            &.icon-check {
                                font-size: 18px;
                            }
                        }
        
                        //COLORS
                        &--purple {
                            background-color: $color-primary-purple-01;
                            border-color: $color-primary-purple-01;
                        }
        
                        &--red {
                            background-color: $color-primary-carnation-01;
                            border-color: $color-primary-carnation-01;
                        }
        
                        &--green {
                            background-color: $color-primary-java-01;
                            border-color: $color-primary-java-01;
                        }
        
                        &--orange {
                            background-color: $color-accent-orange-03;
                            border-color: $color-accent-orange-03;
                        }
                    }
        
                    .dropdown {
                        margin-left: 4px;
                        .button-icon {
                            i[class^='icon-'] {
                                color: $color-neutral-05;
                            }
                        }
        
                        &__trigger {
                            &.button-icon {
                                +.dropdown__menu {
                                    right: inherit;
                                    left: 0;
                                }
                            }
                        }
                    }
        
                }

                &__add-below,
                &__add-above{
                    .button-icon{
                        @include position ($p: absolute, $l:50%, $b: -24px);
                        opacity: 0;
                        transform: translate(-50%, -50%);
                        background-color: $color-neutral-02;
                        visibility: hidden;
                        z-index: 100;
                        &:hover{
                            background-color: $color-primary-purple-01;
                        }
                    }
                }
                &__add-above{
                    .button-icon{
                        @include position ($p: absolute, $l:50%, $t: 0px);
                    }
                }
            }

            &:hover{
                .node{
                    &__add-below,
                    &__add-above{
                        .button-icon{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }

            //Status
            &--focus {
                border: 2px solid $color-primary-purple-01;
                outline: 4px solid rgba(119, 86, 219, .20);
                box-shadow: none;
                
            }
    
            &--empty {
                @include flex($ai: center, $jc: center);
                @include position ($p: relative);
                background-color: $color-neutral-04;
                color: $color-neutral-07;
                box-shadow: none;
                .node{
                    &__actions{
                        @include position ($p: absolute, $t:50%, $r:16px);
                        transform: translateY(-50%);
                        opacity: 0;
                        visibility: hidden;
                        @include flex-child($as:center);
                        transition: opacity .15s ease-in-out;
                    }
                }
                &:hover{
                    .node{
                        &__actions{
                            opacity: 1;
                            visibility: visible;
                            transition: opacity .15s ease-in-out;
                        }
                    }
                }
                &--active {
                    @include flex($ai: center, $jc: center);
                    border: 1px dashed $color-primary-purple-01;
                    background-color: $color-neutral-03;
                    color: $color-primary-purple-01;
                    box-shadow: none;
                }
                &--tutorial{
                    border: 4px solid transparent;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -8px; 
                        border-radius: inherit; 
                        background: linear-gradient(160deg, $color-primary-carnation-01,$color-primary-purple-01);
                      }
                }
            }
        }
        &__button {
            @include flex($ai: center, $jc: center);
            padding: 24px 0 0 0;
        }
        &__dot{
            @include position ($p: absolute, $l:50%, $b: -60px);
            width: 30px;
            height: 30px;
            transform: translate(-50%, -50%);
            @include flex($ai:center, $jc:center);
            z-index: 1000;
            cursor: pointer;
            .dot{
                &__dot{
                    height: 12px;
                    width: 12px;
                    border-radius: 100px;
                    background-color: $color-primary-cherry-pie-01;
                    &--tutorial{
                        background: linear-gradient(160deg, $color-primary-carnation-01,$color-primary-purple-01);
                    }
                }
            }
            .button-icon{
                @include position ($p: absolute, $l:30px, $b: 3px);
                opacity: 0;
                background-color: $color-neutral-02;
                visibility: hidden;
            }
            &:hover{
                .button-icon{
                    opacity: 1;
                    visibility: visible;
                    transition: all .15s ease-in-out;
                }
            }
        }

        &--disabled{
            opacity: 0.5;
            pointer-events: none;
        }
    }
}