.row {
    @include flex($fw: wrap);
    margin-top: -12px;
    margin-left: -8px;
    margin-right: -8px;

    > * {
        @include flex-child($fs:0);
        width: 100%;
        max-width: 100%;
        margin-top: 12px;
        padding-right: 8px;
        padding-left: 8px;    
    }

    .col-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    [class^='col-']{
        + [class^='col-']{
           // margin-top: 12px;
        }
        .input-group,
        .dropdown__trigger{
            .input,
            &.input{
                max-width: 100%;
            }
        }
    }

    + .row{
        margin-top: 12px;
    }
}