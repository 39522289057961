.sidebar-item{
    @include flex($ai:center, $jc:space-between);
    background-origin: $color-neutral-01;
    border: 1px solid $color-neutral-04;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    cursor: pointer;
    &__icon{
        @include flex($ai:center, $jc:center);
        @include flex-child($fs:0);
    }
    &__text{
        @include flex-child($fg:2);
        padding: 0 8px;
        color: $color-neutral-07;
    }
    .tooltip{
        @include flex($ai:center, $jc:center);
        i[class^='icon-']{
            color:$color-neutral-05;
        }
    }
    &--conditional{
        border-radius: 100px;
        .sidebar-item{
            &__text{
                color:  $color-primary-cherry-pie-01;
            }
        }
    }

    &--disabled{
        background-color: $color-neutral-04;
        opacity: .5;
        pointer-events: none;
    }

    &--active{
        background-color: $color-primary-cherry-pie-01;
        color: $color-neutral-01;
        .sidebar-item{
            &__text{
                color: $color-neutral-01;
            }
        }
    }

    + .sidebar-item{
        margin-top: 8px;
    }
}