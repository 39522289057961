.collapse-action{
    @include position ($p: relative);
    background-color: $color-neutral-04;
    padding: 8px 16px;
    border: 1px solid $color-neutral-04;
    border-radius: 8px;
    transition: all .15s ease-in-out;
    &__header{
        @include flex($ai:center, $jc:space-between);
        transition: all 0.15s ease-in-out;
        &__title{
            @include flex-child($fg:2);
            .title{
                &__title{
                    @include flex($ai:center);
                    font-size: 12px;
                    line-height: 16px;
                    transition: all 0.15s ease-in-out;
                }
                &__option-selected{
                    margin-top: 4px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        &__action{
            @include flex-child($fs:0);
            @include flex();
            margin-left: 8px;
            i[class^='icon-']{
                font-size: 24px;
                color: $color-neutral-05;
                transition: all .15s ease-in-out;
            }
        }
    }
    &__body{
        @include flex($fd:column, $ai:flex-start);
        max-height: 0;
        transition: max-height 0.15s ease-in-out;
        opacity: 0;
        transition: max-height 0.15s ease-in-out, opacity  0.15s ease-in-out;
        &__separator{
            border-top: 1px solid $color-neutral-04;
            width: 100%;
            margin: 0 0 24px 0;
        }
        &__buttons{
            @include flex($ai:center, $jc:center);
            margin-top: 24px;
            .button{
                margin-top: 0;
                + .button{
                    margin-left: 16px;
                }
            }
            app-button{
                margin-top: 0;
                + app-button{
                    margin-left: 16px;
                }
            }
        }
        &:only-child{
            @include flex($fd:column, $ai:center);
            .alice{
                margin: 12px auto 24px auto;
            }
            span{
                font-size: 14px;
                line-height: 22px;
                text-align: center;
            }
        }
        .input,
        .switcher-group,
        .dropdown{
            max-width: 100%;
            width: 100%;
            &__menu{
                max-width: 100%;
            }
        }
        .switcher-group{
            margin-top: 8px;
        }
        .input-group{
            &--collapsed-input{
                width: 100%;
                margin-bottom:24px;
                .input{
                    padding: 4x 16px 4px 26px;
                    min-height: 32px;
                    height: 32px;
                    font-size: 16px;
                    line-height: 24px;
                }
                i[class^='icon-']{
                    top: 8px;
                    left: 14px;
                    color: $color-neutral-05;
                }
            }
        } 
        .chip{
            @include flex($jc:space-between);
            background-color: transparent;
            color: $color-primary-cherry-pie-01;
            padding: 0;
            width: 100%;
            margin-top: 24px;
            .button-icon{
                color: $color-neutral-06;
                margin-left: 16px;
            }
        }
        .button--toggle__wrapper{
            margin-bottom: 24px;
        }
    }
    &--open{
        background-color: $color-neutral-01;
        padding: 24px;
        transition: all .15s ease-in-out;
        .collapse-action{
            &__header{
                margin-bottom: 24px;
                transition: all 0.15s ease-in-out;
                &__title{
                    .title{
                        &__title{
                            font-size: 14px;
                            line-height: 18px;
                            transition: all 0.15s ease-in-out;
                        }
                    }
                }
                &__action{
                    i[class^='icon-']{
                        transform:rotate(90deg);
                        transition: all .15s ease-in-out;
                    }
                }
            }
            &__body{
                max-height: 100vh;
                overflow: inherit;
                opacity: 1;
                transition: max-height 0.15s ease-in-out,opacity 0.15s ease-in-out 0.15s;
            }
        }
    }

    + .collapse-action{
        margin-top: 32px;
        &:before{
            @include position ($p: absolute, $l:50%, $t:-17px);
            content: "";
            height: 32px;
            background-color: $color-neutral-04;
            width: 1px;
            transform: translate(-50%, -50%);
        }
    }
}