.workspace-sidebar{
    @include flex();
    @include flex-child($fs:0);
    @include position ($p: fixed, $t:72px, $l:-500px, $b:0);
    // @include position ($p: fixed, $t:113px, $l:-500px, $b:0);
    background-color: $color-neutral-01;
    background-clip: padding-box;
    width: 400px;
    height: 100%;
    border-right: 1px solid $color-neutral-04;
    border-left: 1px solid $color-neutral-04;
    z-index: 100;
    transition: left .5s ease;

    &--open{
        left: 0;
        transition: left .5s ease;
    }
    &__icons{
        padding: 32px 8px;
        border-right: 1px solid $color-neutral-04;
        .button-icon{
            width: 40px;
            height: 40px;
            color: $color-neutral-07;
            .badge{
                @include position ($p: absolute, $t:4px, $r:3px);
            }
            &--selected{
                background-color: $color-neutral-03;
                color: $color-primary-cherry-pie-01 ;
            }
            &--warning{
                color: $color-accent-orange-03;
            }
            +  .button-icon {
                margin-top: 16px;
            }
        } 
    }
    &__sidebar{
        @include flex($fd:column);
        @include flex-child($fg:2);
        &__header {
            @include flex($ai: center, $jc: space-between);
            padding: 24px;
            .header{
                &__title{
                    color: $color-neutral-06;
                    font-size: 18px;
                    line-height: 26px;
                }
                &__actions {
                    margin-left: 16px;
                    .button-icon {
                        i[class^="icon-"] {
                            font-size: 24px;
                            color: $color-neutral-06;
                        }
                    }
                }
            }
        }
        &__actions{
            padding: 0 24px 24px 24px;
            @include flex($ai:center, $jc:space-between);
            .input-group{
                @include flex-child($fg:2);
                width: 100%;
                + .button-icon,
                + .dropdown{
                    margin-left: 16px;
                }
            }
            .button-icon{
                @include flex-child($fs:0);
                border-color:$color-neutral-04;
                i[class^='icon-']{
                    color:$color-neutral-04;
                }
                + .button-icon,
                + .dropdown{
                    margin-left: 16px;
                }
            }
        }
        &__body{
            padding: 0 24px 24px 24px;
            max-height: calc(100vh - 193px);
            overflow: auto;
            @include flex-child($fg:2);
        }
    }
    &--right{
        @include position ($p: fixed, $t:72px, $r:-402px, $b:0, $l:inherit);
        // @include position ($p: fixed, $t:113px, $r:-402px, $b:0, $l:inherit);
        transition: right 0.5s ease;
        width: 459px;
        &.workspace-sidebar{
            &--open{
                right: 0;
                transition: right 0.5s ease;
            }
        }
        .workspace-sidebar{
            &__sidebar{
                &__body{
                    max-height: calc(100vh - 257px);
                }
                &--alerts{
                    .workspace-sidebar{
                        &__sidebar{
                            &__body{
                                max-height: calc(100vh - 194px);
                                .sidebar-item{
                                    padding: 8px 16px;
                                    + .node-preview{
                                        margin-top: 24px;
                                    }
                                }
                                .node-preview{
                                    border: none;
                                    padding: 8px 0;
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .collapse-action{
            background-color: transparent;
            + .collapse-action{
                margin-top: 8px;
                &:before{
                    display: none;
                }
            }
        }
    }
}