.toolbar {
    @include flex($jc: space-between, $ai:flex-start);
    margin: 32px 0 16px 0;

    &__left,
    &__right {
        @include flex($ai: center);

        .button {
            &--secondary{
                border-color: $color-neutral-04;
                color: $color-neutral-06;
                font-weight: 400;
                border-radius: 4px;
                padding: 10px 8px 10px 16px;
                i[class^='icon-']{
                    color: $color-neutral-05;   
                }
                &:hover:not(disabled){
                    color: $color-neutral-06;
                    background-color: transparent;
                }
                &:disabled{
                    pointer-events: none;
                    background-color: $color-neutral-02;
                    border-color: $color-neutral-04;
                    color: $color-neutral-06;
                }
            }
        }

        .dropdown,
        app-dropdown-select {
            + .button,
            + app-button {
                margin-left: 16px;
            }
        }
    }

    &__left {
        @include flex($fd:column, $ai:flex-start);
        .input-group {
            &--collapsed-input {
                width: 400px;
            }
        }
       .chip-group{
            padding-top: 16px;
        }
        + .toolbar__right{
            margin-left: 16px;
        }
    }
    &__right {
        .input-group {
            &--collapsed-input {
                margin-right: 16px;
            }
        }
    }

    + app-chips{
        .chip-group{
            margin-bottom: 32px;
        }
    }
}