.switcher {
    @include position ($p: relative);
    display: block;
    cursor: pointer;
    width: 52px;
    height: 30px;
    input {
        opacity: 0;
        height: 0;
        width: 0;
    }
    &__control {
        @include position ($p: absolute, $t:0, $l:0);
        position: absolute;
        height: 30px;
        width: 52px;
        border-radius: 100px;
        background-color: $color-neutral-05;
        border: 2px solid $color-neutral-01;
        transition: all .15s ease-in;

        &:after {
            @include position ($p: absolute, $t:3px, $r:24px);
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 100px;
            background: $color-neutral-01;
            transition: all .15s ease-in;
        }
    }
    input:checked ~ .switcher__control {
        background-color: $color-primary-java-01;
        &:after {
            @include position ($p: absolute, $r: 3px);
        }
    }

    //SMALL
    &--small{
        width: 30px;
        height: 16px;
        .switcher{
            &__control {
                width: 30px;
                height: 16px;
                border: none;
                &:after {
                    @include position ($p: absolute, $t:2px, $r:16px);
                    width: 12px;
                    height: 12px;
                }
            }
        }
        input:checked ~ .switcher__control {
            &:after {
                @include position ($p: absolute, $r: 2px);
            }
        }
    }

    &-group{
        @include flex($ai:center, $jc:space-between);
        label:not(.switcher){
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            @include flex-child($fg:2);
            margin-bottom: 0;
        }
        .switcher{
            margin: 0 16px;
        }
        i[class^='icon-']{
            color: $color-neutral-05;
        }
        .tooltip{
            @include flex($jc:center, $ai:center);
        }
    }
}