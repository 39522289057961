// .modal-open {
//     overflow: hidden;
//     .container {
//         .content {
//             .main {
//                 overflow: hidden;
//             }
//         }
//     }
// }

.tc-modal{
    width: 100%;
    .mat-dialog-container {
        padding: 0px;
        border-radius: 8px;
        box-shadow: none;
        margin: 0px 20px;
    }
}
.modal {
    &__dialog {
        @include flex($jc: center);
        &__content {
            @include flex-child($fg: 2, $fs: 0);
            background-color: $color-neutral-01;
            border: 1px solid $color-neutral-04;
            max-width: 800px;
            border-radius: 8px;
            width: 100%;

            .content {
                &__header {
                    @include flex($ai: center, $jc: space-between);
                    padding: 24px 40px;
                    border-bottom: 1px solid $color-neutral-04;
                    &__title{
                        @include flex($ai:center);
                        .tag-icon,
                        app-tag-icon{
                            + .tag-icon{
                                margin-left: 4px;
                            }
                            + h3{
                                margin-left: 16px;
                            }
                        }
                    }
                    .button-icon {
                        @include flex-child($fs: 0);
                        color: $color-neutral-06;
                        padding: 0;
                        i[class^='icon-'] {
                            font-size: 24px;
                        }
                    }
                }

                &__body {
                    padding: 24px 40px;
                    &__section{
                        .tile{
                            padding: 32px;
                        }
                        + .content__body__section{
                            border-top: 1px solid #EAE8F0;
                            margin-top: 32px;
                            padding-top: 32px;
                        }
                    }
                    + .content__footer{
                        padding-top: 40px;
                    }
                }

                &__footer {
                    @include flex($jc: center, $ai: center);
                    padding: 64px 40px;

                    app-button + app-button,
                    button +  button {
                        margin-left: 16px;
                    }
                }
            }
        }
    }
   
    //MODAL CONFIRMATION
    &--confirmation {
        .modal {
            &__dialog {
                &__content {
                    .content {
                        &__header {
                            @include flex($jc:flex-end);
                            padding: 40px 40px 16px 40px;
                            border: none;
                        }

                        &__body {
                            @include flex($fd:column, $ai:center);
                            padding-top: 0;
                            text-align: center;
                            > .alice,
                            app-alice{
                                margin-bottom: 24px;
                                margin-top: 16px;
                            }
                            .tag-icon{
                                border-radius: 100px;
                                width: 64px;
                                height: 64px;
                                margin-bottom: 24px;
                                border: none;
                                i[class^='icon-']{
                                    font-size:32px;
                                }
                                &--green{
                                    background-color: rgba(24, 214, 200, 0.20);
                                    color: $color-primary-java-01;
                                    
                                }
                                &--orange{
                                    background-color: rgba(247, 95, 0, 0.20);
                                    color: $color-accent-orange-02;
                                }
                            }
                            h4{
                                + h6{
                                    margin-top: 8px;
                                }
                            }
                            h6{
                                font-weight: 400;
                                color: $color-neutral-07;
                                b{
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //MODAL CARDS
    &--cards{
        .modal{
            &__dialog{
                &__content{
                    max-width: 1000px;
                    .content{
                        &__body{
                            max-height: 500px;
                            overflow: auto;
                            > .input-group{
                                &.input-group--collapsed-input{
                                    width: 100%;
                                    margin-bottom: 32px;
                                }
                            }
                            .toolbar{
                                margin: 0 0 32px 0;
                            }
                            &__section{
                                @include flex($fw:wrap);
                                margin: -12px;
                            }
                            .card{
                                &:not(.card--people){        
                                    flex-basis: 30%;
                                    min-width: 146px;
                                }
                            }
                        }
                        &__footer{
                            border: 1px solid $color-neutral-04;
                            padding-top: 32px;
                        }
                    }
                }
            }
        }
    }
}
