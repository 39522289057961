.error-message {
    @include flex($fd: column, $ai: center);
    text-align: center;
    color: $color-neutral-07;
    h5 {
        padding: 16px 0 4px 0;
        color: $color-neutral-07;
    }

    .button {
        margin-top: 16px;

        i[class^='icon-'] {
            margin-left: 0;
            margin-right: 4px;
        }
    }
}