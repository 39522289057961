.tile{
    @include flex($fd:column);
    border: 1px solid $color-neutral-04;
    padding: 40px;
    border-radius: 8px;
    .tile{
        padding: 24px;
    }
    &__header{
        @include flex($ai:center, $jc:space-between);
        padding-bottom: 24px;
        .tabs,
        app-tabs{
            margin-bottom: 0;
            margin-left: 16px;
        }
        &__actions{
            padding-left: 16px;
            @include flex($ai:center, $jc:flex-end);
            .input-group{
                + .dropdown,
                + app-dropdown-select{
                    margin-left: 16px;
                }
            }
        }
    }
    &__body{
        @include flex-child($fg:2);
        &__section{
            + .tile__body__section{
                border-top: 1px solid #EAE8F0;
                margin-top: 16px;
                padding-top: 16px;
            }
        }
        &--flex{
            @include flex($ai:center, $jc:space-between);
            .tile__body__section{
                @include flex-child($fg:2);
                padding: 0 32px 0 16px;
            }
        }
    }
    &__footer{
        @include flex($ai:center, $jc:space-between);
        padding-top: 24px;
    }

    app-dropdown-select,
    .dropdown{
        .dropdown,
        &.dropdown{
            &--open{
                .dropdown{
                    &__menu{
                        left: inherit;
                    }
                }
            }
        }
    }

    //DASHBOARD-TILE
    &--dashboard{
        @include flex($fd:row);
        padding: 24px;
        .tile{
            &__information{
                @include flex($fd:column, $jc:space-between, $ai:flex-start);
                @include flex-child($fs:0, $fg:2);
                &__title{
                    text-transform: uppercase;
                    color: $color-neutral-06;
                    font-size: 12px;
                    margin-bottom: 12px;
                }
                + .tile__tags{
                    margin-left: 8px;
                }
            }
            &__tags{
                @include flex($fd:column, $jc:space-between, $ai:flex-end);
                @include flex-child($fs:0);
            }
        }
        &--thin{
            padding: 16px;
            @include flex($fd:column);
            .toolbar{
                margin: 0 0 16px 0;
            }
            .tile{
                @include flex($jc:space-between, $fd:row, $ai:center);
                padding: 8px 16px;
                + .tile{
                    margin-top: 8px;
                }
            }
            
            //SKELETON
            &--skeleton{
                .toolbar{
                    &__left{
                        &:before{
                            content: '';
                            display: block;
                            width: 40px;
                            height: 40px;
                            border-radius: 100px;
                            @extend %skeleton;
                        }
                        > * {
                            display: none;
                        }
                    }
                    &__right{
                        display: none;
                    }
                }
                .tile{
                    padding: 16px;
                    min-height: 73px;
                    &__information{
                        &__info{
                            max-width: 200px;
                            width: 100%;
                            height: 16px;
                            @extend %skeleton;
                        }
                    }
                    &__tags{
                        margin-left: 16px;
                        &:after{
                            content: '';
                            display: block;
                            width: 88px;
                            height: 16px;
                            @extend %skeleton;
                        }
                        > * {
                            display: none;
                        }
                    }
                }
            }
        }

        //SKELETON
        &--skeleton{
            .tile{
                &__information{
                    &__title{
                        width: 176px;
                        height: 16px;
                        margin-bottom: 24px;
                        @extend %skeleton;
                    }
                    &__info{
                        h2{
                            width: 88px;
                            height: 16px;
                            margin-bottom: 24px;
                            @extend %skeleton;
                        }
                        h5{
                            width: 176px;
                            height: 16px;
                            @extend %skeleton;                       
                        }
                    }
                }
                &__tags{
                    &:before{
                        content: '';
                        display: block;
                        width: 32px;
                        height: 32px;
                        margin-bottom: 24px;
                        @extend %skeleton;
                        border-radius: 100px;
                    }
                    &:after{
                        content: '';
                        display: block;
                        width: 88px;
                        height: 16px;
                        @extend %skeleton;
                    }
                    > * {
                        display: none;
                    }
                }
            }
        }
    }

    //PROFILE-TILE
    &--profile{
        .tile{
            &__header{
                padding-bottom: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid $color-neutral-04;
            }
            &__body{
                .table{
                    tbody{
                        tr{
                            .table{
                                &__cell{
                                    .avatar-group{
                                        .avatar{
                                            width: 64px;
                                            height: 64px;
                                            margin-right: 4px;
                                            span{
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
            }
        }
        app-dropdown-select,
        .dropdown{
            .dropdown,
            &.dropdown{
                &--open{
                    .dropdown{
                        &__menu{
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    //EMPTY STATES
    &--empty-states{
        background-color: $color-neutral-02;
        .tile{
            &__header{
                margin-bottom: 16px;
                padding-bottom: 0;
            }
            &__body{
                @include flex($fd:column, $ai:center);
                h5{
                    padding: 16px 0 4px 0;
                }
                .button{
                    margin-top: 16px;
                    i[class^='icon-']{
                        margin-left:0;
                        margin-right: 4px;
                    }
                }
            }
        }
        
        //SKELETON
        &--skeleton{
            background-color: $color-neutral-01;
            .tile{
                &__header{
                    width: 191px;
                    height: 16px;
                    @extend %skeleton;
                    h5{
                        display: none;
                    }
                }
                &__body{
                    @include flex($jc:center);
                    img{
                        display: none;
                    }
                    h5{
                        font-size: 0;
                        line-height: 16px;
                        &:before{
                            content: '';
                            display: block;
                            width: 64px;
                            height: 64px;
                            margin: 0 auto;
                            @extend %skeleton;
                            border-radius: 100px;
                        }
                        &:after{
                            content: '';
                            display: block;
                            width: 220px;
                            height: 16px;
                            margin: 0 auto;
                            @extend %skeleton;
                        }
                    }
                    div{
                        font-size: 0;
                        &:before{
                            content: '';
                            display: block;
                            width: 220px;
                            height: 16px;
                            margin-top: 16px;
                            @extend %skeleton;
                        }
                        &:after{
                            content: '';
                            display: block;
                            width: 88px;
                            height: 32px;
                            margin: 16px auto 0 auto;
                            @extend %skeleton;
                        }
                    }
                    .button{
                        display: none;
                    }
                }
            }
        }
    }
}