.dropdown{
    @include position ($p: relative);
    max-width: fit-content;
    &-group{
        @include flex($fd:column);
        width: 100%;
        label:not(.checkbox){
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: 700;
            line-height: 18px;
            color: $color-primary-cherry-pie-01;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }   
    }
    &__trigger{
        cursor: pointer;
        
        &.input{
            padding: 8px 8px 8px 16px;
            span{
                @include flex-child($fg:2);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                color: $color-neutral-06;
            }
            .icon-chevron-down{
                color: $color-neutral-05;
                transition: all .15s ease-in-out;
                font-size: 16px;
                margin-left: 8px;
                transform: rotate(-90deg);
            }

            &:focus:not(.dropdown__trigger){
                .icon-chevron-down{
                    transition: all .15s ease-in-out;
                    transform: rotate(0deg);
                }
            }
        }

        //avatar
        .avatar{
            width: 32px;
            height: 32px;
        }

        //button-icon
        &.button-icon{
            .icon-chevron-down{
                transition: all .15s ease-in-out;
                color: $color-neutral-06;
                font-size: 16px;
                margin-left: 4px;
            }
            &:hover:not(.button-icon--circle){
                background-color: transparent;
                .icon-chevron-down{
                    transition: all .15s ease-in-out;
                    color: $color-neutral-07;
                }
            }
            + .dropdown__menu{
                @include position ($p: absolute, $r:0, $l:inherit);
                min-width: 144px;
            }
        }
    }

    app-button-icon{
        + .dropdown__menu{
            @include position ($p: absolute, $r:0, $l:inherit);
            min-width: 144px;
        }
    }

    &__menu{
        @include position ($p: absolute, $l: 0, $r:0, $t: 46px);
        max-height: 0px;
        visibility: hidden;
        opacity: 0; 
        box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.05), 0px 12px 24px rgba(0, 0, 0, 0.05);
        background-color: $color-neutral-01;
        border: 1px solid $color-neutral-04;
        border-radius: 4px;
        overflow: auto;
        font-size: 14px;
        line-height: 18px;
        color: $color-primary-cherry-pie-01;
        z-index: 1000;
        padding: 0 8px;
        transition: opacity .5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );
        max-width: 300px;
        &__item{
            @include flex($ai:center);
            transition: all .15s ease-in-out;
            padding: 3px 8px;
            border-radius: 4px;
            cursor: pointer;
            i[class^='icon-']{
                font-size: 16px;
                margin-right: 8px;
            }
            .switcher{
                margin-left: 10px;
            }
            &--selected{
                color: $color-primary-purple-01;
                pointer-events: none;
            }
            &:hover{
                transition: all .15s ease-in-out;
                background-color: $color-neutral-02;
            }
            + .dropdown__menu__item{
                margin-top: 8px;
            }
        }
        &__separator{
            border-top: 1px solid $color-neutral-04;
            margin: 8px 0;
        }
    }

    &--open{
        .dropdown{
            &__trigger{
                .icon-chevron-down{
                    transform: rotate(-90deg);
                    transition: transform .15s ease-in-out;
                    color: $color-neutral-07;
                }
                &.input{
                    border-color: $color-primary-cherry-pie-01;
                    .icon-chevron-down{
                        color: $color-neutral-05;
                        transition: all .15s ease-in-out;
                        transform: rotate(0deg);
                    }
                }
                &.button-icon--circle{
                    background-color: $color-primary-purple-01;
                    border-color:$color-primary-purple-01;
                    i[class^='icon-']{
                        color:$color-neutral-01;
                    }
                }
            }
            &__menu{
                max-height: 176px;
                padding: 8px;
                visibility: visible;
                opacity: 1;
                transition: opacity .5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );        
                min-width: 140px;
            }
        }
        app-button-icon,
        .button-icon{
            &[data-tooltip]{
                &:before{
                    display: none;
                }
            }
        }
    }

    &--filled{
        .dropdown{
            &__trigger{
                &.input{
                    span{
                        color: $color-neutral-07;
                    }
                    &:disabled{
                        span{
                            color: $color-neutral-06;
                        }
                    }
                }
            }
        }
    }
}