.rating{
    @include position ($p: relative);
    @include flex();
    color: $color-neutral-04;
    &__input{
        @include position ($p: absolute);
        width: 0;
        height: 0;
        border: 0;
        overflow: hidden;
        opacity: 0;
        &:nth-of-type(1):checked ~ .rating__star:nth-of-type(1),
        &:nth-of-type(2):checked ~ .rating__star:nth-of-type(-n+2),
        &:nth-of-type(3):checked ~ .rating__star:nth-of-type(-n+3),
        &:nth-of-type(4):checked ~ .rating__star:nth-of-type(-n+4),
        &:nth-of-type(5):checked ~ .rating__star:nth-of-type(-n+5),
        &:nth-of-type(6):checked ~ .rating__star:nth-of-type(-n+6),
        &:nth-of-type(7):checked ~ .rating__star:nth-of-type(-n+7),
        &:nth-of-type(8):checked ~ .rating__star:nth-of-type(-n+8),
        &:nth-of-type(9):checked ~ .rating__star:nth-of-type(-n+9),
        &:nth-of-type(10):checked ~ .rating__star:nth-of-type(-n+10){
            color: $color-accent-yellow-01;
        }
    }
    &__star{
        cursor: pointer;  
        padding: 0 1px;
        i[class^='icon-']{
            font-size: 12px;
            fill: $color-neutral-04;
        }
        &:hover{
            ~ .rating__star{
                color: $color-neutral-04;
            }
        }
    }
    &:hover{
        color: $color-accent-yellow-01;
    }
    &--disabled{
        pointer-events: none;
        color: $color-neutral-04;
    }
}