.tag-icon {
    @include flex($ai: center, $jc: center);
    border: 1px solid $color-neutral-04;
    background-color: $color-neutral-01;
    padding: 8px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    color: $color-neutral-07;
    font-size: 18px;
    line-height: 26px;
    i[class^='icon-']{
        color: $color-primary-cherry-pie-01;
        font-size: 26px;
    }

    //COLORS
    &--purple{
        i[class^='icon-']{
            color: $color-primary-purple-01;
        }
    }
    &--red{
        i[class^='icon-']{
            color: $color-primary-carnation-01;
        }
    }
    &--green{
        i[class^='icon-']{
            color: $color-primary-java-01;
        }
    }
    &--orange{
        i[class^='icon-']{
            color: $color-accent-orange-02;
        }
    }

    //SIZES
    &--small {
        width: 32px;
        height: 32px;
        padding: 6px;
        font-size: 14px;
        line-height: 18px;
        i[class^='icon-']{
            font-size: 22px;
        }
    }

    &--large {
        width: 48px;
        height: 48px;
        i[class^='icon-']{
            font-size: 32px;
        }
    }

    &--xl {
        width: 64px;
        height: 64px;
        i[class^='icon-']{
            font-size: 46px;
        }
    }

    + .tag-icon {
        margin-left: 4px;
    }
}
app-tag-icon + app-tag-icon {
    margin-left: 4px;
}