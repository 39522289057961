.item{
    border-radius: 8px;
    border: 1px solid $color-neutral-04;
    cursor: pointer;
    &__header{
        @include flex($jc:space-between);
        padding: 12px 24px 12px 8px;
        border-radius: 8px;
        background-color: $color-neutral-01;
        transition: background-color .15s ease-in-out;
        &__info{
            @include flex($ai:center);
            @include flex-child($fg:2);
            padding-right: 32px;
            .info{
                &__notification{
                    width: 8px;
                    margin-right: 8px;
                }
                &__date{
                    font-size: 14px;
                    color: $color-neutral-07;
                    white-space: nowrap;
                }
            }
            h3{
                margin-right: 16px;
            }
            h6{
                @include flex-child($fg:2);
                padding: 0 16px;
            }
        }
        &__actions{
            @include flex($ai:center);
            @include flex-child($fs:0);
            .switcher,
            app-switcher{
                + .dropdown,
                + app-dropdown{
                    margin-left: 32px;
                }
            }
            .button-icon{
                transition: transform .15s ease-in-out;
                color: $color-neutral-06;
                padding: 0;
                &:hover{
                    background-color: transparent;
                }
            }
            .dropdown,
            app-dropdown{
                &--open {
                    .dropdown {
                        &__menu {
                            width: 200px;
                            max-height: fit-content;
                            top: 40px;
                            right: 8px;
                        }
                    }
                }
                + .button-icon,
                + app-button-icon{
                    margin-left: 32px;
                }
            }
        }
    }
    &__body{
        @include flex($fd:column, $ai:flex-start);
        padding: 0 24px;
        max-height: 0;
        transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out;
        overflow: hidden;
        &__action{
            margin-top: 24px;
        }

    }
    //OPEN
    &--open{
        .item{
            &__header{
                border-radius: 8px 8px 0 0 ;
                background-color: $color-neutral-02;
                transition: background-color .15s ease-in-out;
                &__actions{
                    > .button-icon{
                        transition: transform .15s ease-in-out;
                        transform: rotate(90deg);
                    }
                    // Rafa
                    > app-button-icon{
                        transition: transform .15s ease-in-out;
                        transform: rotate(90deg);
                    }
                }
            }
            &__body{
                max-height: 100vh;
                border-top: 1px solid $color-neutral-04;
                padding: 24px;
                transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out;
            }
        }
    }
    
    + .item{
        margin-top: 16px;
    }
    
    //BIG
    &--big{
        .item{
            &__header{
                padding: 30px 24px;
            }
        }
    }

    //SKELETON
    &--skeleton{
        .item{
            &__header{
                padding: 16px 24px;
                @include flex($ai:center);
                &__info{
                    .tag-icon{
                        @include flex-child($fs:0);
                        display: block;
                        width: 32px;
                        height: 32px;
                        margin-right: 16px;
                        border: none;
                        @extend %skeleton;
                        border-radius: 100px;
                        i[class^='icon-']{
                            display:none;
                        }
                    }
                    h6{
                        max-width: 400px;
                        width: 100%;
                        height: 16px;
                        @extend %skeleton;
                    }
                }
                &__actions{
                    max-width: 212px;
                    width: 100%;
                    height: 16px;
                    @extend %skeleton;
                }
            }
        }
        margin-bottom: 16px;
    }
}