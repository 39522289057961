.popover{
    @include position ($p: relative);
    cursor: pointer;
    &__menu{
        @include position ($p: absolute);
        @include flex($fd:column);
        //background-color:$color-neutral-09 ;
        //border: 1px solid $color-neutral-05;
        border-radius: 16px;
        z-index: 1000;
        min-width: 358px;
        height: 0px;
        padding: 24px;
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s ease-in-out;
        box-shadow: 0px 0px 23px -16px rgba(0, 0, 0, 0.6);
        &__header{
            @include flex($ai:center, $jc:space-between);
            padding-bottom: 16px;
            h3{
               // color: $color-primary-blue-01;
            }
            a{
                //font-size: $font-size-16;
            }
        }
        &__body{
            @include flex-child($fg:2);
            &--empty{
                @include flex($fd:column, $ai:center, $jc: center);
                height: 282px;
                //color: $color-neutral-03;
                font-weight: 500;
                i[class^='icon-']{
                    font-size: 32px;
                    margin-bottom: 20px;
                }
            }
        }
        &__footer{
            @include flex($ai:center, $jc:flex-end);
            padding: 15px 16px;
           // font-size: $font-size-16;
        }
    }
    &--open{
        .popover{
            &__menu{
                height: auto;
                visibility: visible;
                opacity: 1;
                transition: opacity .15s ease-in-out;
            }
        }
    }
}