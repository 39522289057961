.tag{
    @include flex($ai:center, $jc:center);
    background-color: $color-neutral-03;
    color: $color-primary-cherry-pie-01;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    padding: 4px 8px;
    text-transform: uppercase;
    width: fit-content;

    //DASHBOARD
    &--dashboard{
        border-radius: 100px;
        text-transform: capitalize;
        font-size: 14px;
        line-height: 18px;
        i[class^='icon-']{
            font-size: 16px;
            margin-right: 4px;
        }
        &.tag{
            &--success{
                background-color: rgba(24, 214, 200, 0.10);
                color: $color-primary-java-01;
                
            }
            &--warning{
                background-color: rgba(247, 95, 0, 0.10);
                color: $color-accent-orange-02;
            }
        }
    }

    //COLORS
    &--success{
        background-color: $color-feedback-blue;
        color: $color-primary-java-03;
    }
    &--error{
        background-color: $color-feedback-pink;
        color: $color-primary-carnation-01;
    }
    &--neutral{
        background-color: $color-neutral-04;
        color: $color-neutral-07;
    }
    &--warning{
        background-color: $color-feedback-orange;
        color: $color-accent-orange-02;
    }

    //SIZE
    &--small{
        padding: 2px 4px;
    }
    &--big{
        font-size: 16px;
        line-height: 24px;
        padding: 8px;
    }

    + .tag{
        margin-left: 8px;
    }
}

// Rafa: es para que se separen los tags entre ellos en el listado de componentes. Borrálo cuando quieras
app-tag + app-tag{
    margin-left: 8px;
}