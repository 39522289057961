%skeleton {
    background: repeating-linear-gradient(to right, $color-neutral-02 0%, $color-neutral-04 50%, $color-neutral-02 100%);
    background-size: 200% auto;
    background-position: 0 100%;
    animation: skeleton-animation 1.8s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    border-radius: 8px;
}

@keyframes skeleton-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}