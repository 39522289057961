.comments{
    border: 1px solid $color-neutral-04;
    border-radius: 16px;
    padding: 24px;
    font-size: 14px;
    line-height: 18px;
    transition: all .15s ease-in-out;
    cursor: pointer;
    
    //AVATAR
    .avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        span{
            font-size: 12px;
            font-weight: 500;
        }
    }
    
    &__comment{
        &__info{
            @include flex($ai:center, $jc:space-between);
            margin-bottom: 8px;
            .info{
                &__name{
                    @include flex-child($fg:2);
                    @include flex($ai:center, $jc:flex-start);
                    .avatar {
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                        span{
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    &__name{
                        color: $color-primary-cherry-pie-01;
                        font-weight: 700;
                    }
                }
                &__data{
                    font-size: 12px;
                    line-height: 16px;
                    color: $color-neutral-07;
                }
            }
        }
        &__node{
            font-size: 12px;
            line-height: 16px;
            color: $color-primary-cherry-pie-01;
        }
        &__body{
            color: $color-neutral-07;
            margin: 8px 0;
        }
        &__answers{
            @include flex($ai:center, $jc:space-between);
            margin-top: 16px;
            //INPUT-BUTTON
            .input-group{
                &__button{
                    border: 1px solid $color-neutral-04;
                    border-radius: 4px;
                    .input{
                        border: none;
                    }
                    .button-icon{
                        i[class^='icon-']{
                            color: $color-neutral-05;
                        }
                        &:hover{
                            background-color: transparent;
                            i[class^='icon-']{
                                color: $color-primary-cherry-pie-01;
                            }
                        }
                    }
                }
            }
        }
        + .comments__comment{
            margin-top: 32px;
        }
    }
    &:hover:not(.comments--extend){
        background-color: $color-neutral-02;
        transition: all .15s ease-in-out;
    }
    + .comments{
        margin-top: 8px;
    }
}