.card{
    @include flex($fd:column);
    @include flex-child($fg:2, $fb:30%);
    background-color: $color-neutral-01;
    border: 1px solid $color-neutral-04;
    border-radius: 8px;
    padding: 16px;
    transition: all 0.15s ease-in-out;
    margin: 12px;
    cursor: pointer;
    &__header{
        @include flex($fd:column);
        img{
            max-width: 64px;
            margin-bottom: 8px;
        }
        &__title{
            @include flex($ai:center);
            color: $color-primary-cherry-pie-01;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            .tag-icon{
                margin-right: 8px;
            }
        }
        &__info{
            @include flex($ai:center, $jc:space-between);
            font-size: 14px;
            margin-top: 8px;
            color:$color-neutral-06;
            .info{
                &__info{
                    @include flex-child($fg:2);
                }
            }
            .tag{
                margin-left: 16px;
            }
        }
    }
    &__body{
        @include flex-child($fg:2);
        @include flex($jc:space-between, $ai:center, $fw:wrap);
        padding-top: 16px;
    }
    &:hover:not(.card--selected){
        transition: all 0.15s ease-in-out;
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    }
    &--selected{
        background-color: $color-primary-cherry-pie-01;
        border-color: $color-primary-cherry-pie-01;
        .card{
            &__header{
                &__title{
                    color: $color-neutral-01;
                }
            }
        }
    }

    //Adjust margin in flex-container
    &__flex-wrapper{
        @include flex();
        margin: -12px;
        .button.button--soft{
            margin: 12px;
        }
        &--wrap{
            @include flex($fw:wrap);
        }
        app-button{
            @include flex();
            .button{
                &.button--soft{
                    @include flex-child($fg:2);
                }
            } 
        }
        app-skeleton{
            @include flex-child($fg:2);
        }
    }

    //PEOPLE
    &--people{
        padding: 24px 16px 38px 16px;
        min-width: 200px;
        .card{
            &__body{
                @include flex($fd:column);
                font-size: 14px;
                padding-top: 0;
                .avatar{
                    margin-bottom: 24px;
                    transition: all .15s ease-in-out;
                }
                &__name{
                    transition: all .15s ease-in-out;
                    font-weight: 700;
                    margin-bottom: -22px;
                }
                a{
                    transition: opacity .15s ease-in-out;
                    opacity: 0;
                    &:hover{
                        color: $color-primary-carnation-01;
                    }
                }
            }
        }
        &:hover{
            padding-bottom: 24px;
            .card{
                &__body{
                    .avatar{
                        transition: all .15s ease-in-out;
                        margin-bottom: 16px;
                    }
                    &__name{
                        transition: all .15s ease-in-out;
                        margin-bottom: 0px;
                    }
                    a{
                        transition: opacity .15s ease-in-out;
                        opacity: 1;
                    }
                }
            }
        }
    }

    //SKELETON
    &--skeleton{
        padding: 24px;
        pointer-events: none;
        .card{
            &__header{
                &__title{
                    width: 100%;
                    height: 16px;
                    margin-bottom: 16px;
                    @extend %skeleton;
                }
                &__info{
                    width: 110px;
                    height: 16px;
                    margin-top: 0;
                    @extend %skeleton;
                }
            }
            &__body{
                &:before{
                    content: '';
                    display: block;
                    width: 32px;
                    height: 32px;
                    @extend %skeleton;
                    border-radius: 100px;
                }
            }
        }
    }
    &--disabled{
        pointer-events: none;
        opacity: 0.5;
    }
}
