*[data-tooltip]{
    @include position ($p: relative);
    cursor: pointer;
    white-space: nowrap;
    &:before{
        opacity: 0;
        @include position ($p: absolute, $b: -60px, $l:50%);
        transform: translate(-50%, -50%);
        @include flex();
        content: attr(data-tooltip);
        padding: 8px;
        border-radius: 2px;
        font-weight: 400;
        background-color: $color-primary-cherry-pie-01;
        width: fit-content;
        white-space: nowrap;
        color: $color-neutral-01;
        font-size: 14px;
        line-height: 18px;
        transition: all .15s ease-in-out;
        pointer-events: none;
        z-index: 10000;
    }
    &:hover{
        &:before,
        &:after{
            transition: all .15s ease-in-out;
            opacity: 1;
        }
    }

    //Right
    &.tooltip{
        &--right{
            &:before{
                @include position ($p: absolute, $t: 50%, $b:inherit, $r:inherit,$l:32px);
                white-space: normal;    
                transform: translatey( -50%);   
                max-width: 350px;
                width: max-content;              
            }
        }
    }
    //Left
    &.tooltip{
        &--left{
            &:before{
                @include position ($p: absolute, $t: 50%, $b:inherit, $r:32px,$l:inherit);
                white-space: normal;    
                transform: translatey( -50%);      
                max-width: 350px;
                width: max-content;                   
            }
        }
    }
}