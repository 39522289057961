// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DxBot-primary: mat.define-palette(mat.$indigo-palette);
$DxBot-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$DxBot-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$DxBot-theme: mat.define-light-theme((color: (primary: $DxBot-primary,
                accent: $DxBot-accent,
                warn: $DxBot-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($DxBot-theme);

/* You can add global styles to this file, and also import other style files */

// Helpers
@import 'assets/abstracts/_variables.scss',
'assets/abstracts/_mixins.scss',
'assets/abstracts/_helpers.scss';

//Base
@import 'assets/base/_typography.scss';

// Styles
@import 'assets/base/_base.scss';

// Layout
@import 'assets/layout/header',
'assets/layout/grid';

// Components
@import 'assets/components/_alice.scss',
'assets/components/_skeleton.scss',
'assets/components/_button.scss',
'assets/components/_sidebar.scss',
'assets/components/_badge.scss',
'assets/components/_breadcrumb.scss',
'assets/components/_tile.scss',
'assets/components/_tabs.scss',
'assets/components/_progress.scss',
'assets/components/_banner.scss',
'assets/components/_alert.scss',
'assets/components/_tag.scss',
'assets/components/_tag-icon.scss',
'assets/components/_table.scss',
'assets/components/_popover.scss',
'assets/components/_dropdown.scss',
'assets/components/_avatar.scss',
'assets/components/_input.scss',
'assets/components/_card.scss',
'assets/components/_rating.scss',
'assets/components/_tooltip.scss',
'assets/components/_modal.scss',
'assets/components/_switcher.scss',
'assets/components/_toolbar.scss',
'assets/components/_pagination.scss',
'assets/components/_chips.scss',
'assets/components/_filters.scss',
'assets/components/_item.scss', 
'assets/components/_onboarding.scss',
'assets/components/_error-message.scss';

//Workspace
@import 'assets/components/_tree.scss';
@import 'assets/components/_workspace-toolbar.scss';
@import 'assets/components/_workspace-sidebar.scss';
@import 'assets/components/_collapse-action.scss';
@import 'assets/components/_sidebar-item.scss';
@import 'assets/components/_comment.scss';
@import 'assets/components/_node-preview.scss';


// Pages
@import 'assets/pages/_workspace.scss';
@import 'assets/pages/_demoarea.scss';

//Responsive
@import 'assets/base/_responsive.scss';

//TEMPORALY STYLES
.content-component {
    width: 100%;
}