.banner{
    @include flex($jc:center, $fd:column, $ai:center);
    text-align: center;
    background-color: $color-neutral-03;
    border-radius: 8px;
    padding: 32px 40px;
    max-width: 360px;

    //AVATAR
    .avatar {
        width: 32px;
        height: 32px;
        &-group {
            .avatar {
                margin-right: -12px;
                span{
                    font-size: 12px;
                    font-weight: 500;
                }
                &__number {
                    margin-left: 16px;
                }
            }
        }
    }

    &__body{
        padding: 40px 0;
        h5{
            margin-bottom: 8px;
        }
        div{
            color: $color-neutral-07;
            font-size: 14px;
            line-height: 18px;
        }
    }
    &--gray{
        background-color: $color-neutral-02;
    }
}