.node-preview{
    @include flex($ai:center, $jc:space-between);
    background-origin: $color-neutral-01;
    border: 1px solid $color-neutral-04;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    cursor: pointer;
    .tag-icon{
        @include flex-child($fs:0);
        background-color: $color-neutral-03;
        border-color: $color-neutral-03;
    }
    &__text{
        @include flex-child($fg:2);
        padding: 0 16px;
        span{
            color: $color-neutral-06;   
        }
    }
    > i[class^='icon-']{
        font-size: 24px;
        color: $color-neutral-05;
    }
    + .node-preview{
        margin-top: 8px;   
    }
    &--selected{
        border: 2px solid $color-primary-purple-01;
        outline: 4px solid rgba(119, 86, 219, .20);
    }
}