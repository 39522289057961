.filters {
    @include position ($p: fixed, $t: 0, $r: -400px);
    width: 400px;
    height: 100vh;
    background-color: $color-neutral-01;
    border-left: 1px solid $color-neutral-04;
    z-index: 10000;
    overflow-x: hidden;
    transition: right .5s ease;

    &--open{
        right: 0;
        transition: right .5s ease;
    }

    &__header {
        @include flex($ai: center, $jc: space-between);
        padding: 24px;
        &__title{
            color: $color-neutral-06;
            font-size: 18px;
            line-height: 26px;
        }
        &__actions {
            margin-left: 16px;
            .button-icon {
                i[class^="icon-"] {
                    font-size: 24px;
                    color: $color-neutral-06;
                }
            }
        }
    }

    &__body {
        height: calc(100vh - 184px);
        overflow: auto;
        padding: 0px 24px;
        &__section {
            padding: 32px 0;
            &:first-child{
                padding-top: 0;
            }
            .section{
                &__title {
                    margin-bottom: 8px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 18px;
                    color: $color-primary-cherry-pie-01;
                }
            }
            .input{
                max-width: 100%;
            }
            + .filters__body__section{
                border-top: 1px solid $color-neutral-04;
            }
        }
    }

    &__footer {
        @include flex($ai: center, $jc: center);
        padding: 24px 24px 40px 24px;
        .button,
        app-button {
            + .button,
            + app-button{
                margin-left: 16px;
            }
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;

        &.show {
            opacity: 0.2;
        }
    }
}