app-avatar{
    @include flex-child($fs:0);
}
.avatar{
    @include flex($ai:center, $jc: center);
    width: 64px;
    height: 64px;
    border-radius: 100px;
    border: 2px solid $color-neutral-04;
    overflow: hidden;
    @include flex-child($fs:0);
    img{
        width: 100%;
        background-size: cover;
        background-position: center center;
    }

    span{
        font-size: 24px;
    }

    //group
    &-group{
        @include flex($ai:center);
        .avatar{
            &:only-child{
                margin-right: 0;
            }
            margin-right: -24px;
            &:nth-child(1){
                z-index: 100;
            }
            &:nth-child(2){
                z-index: 10;
            }
            &:nth-child(3){
                z-index: 0;
            }

            &__number{
                color: $color-neutral-07;
                line-height: 24px;
                margin-left: 32px;
            }
        }

        //Change avatar
        .button-icon{
            @include position ($p: relative, $t:32px, $r: 8px);
            border-radius: 100px;
            border: 2px solid $color-neutral-05;
            background-color: $color-neutral-01;
            z-index: 100;
            width: 40px;
            height: 40px;
            @include flex-child($fs:0);
            i[class^='icon-']{
                color:$color-neutral-05;
            }
            &:hover{
                background-color:  $color-primary-purple-01;
                border-color: $color-primary-purple-01;
                i[class^='icon-']{
                    color: $color-neutral-01;
                }
            }
        }
    }

    &--big{
        width: 104px;
        height: 104px;
        span{
            font-size: 35px;
        }
    }

}