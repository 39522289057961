.chip{
    @include flex($ai:center);
    font-size: 14px;
    line-height: 18px;
    color: $color-neutral-01;
    background-color: $color-primary-cherry-pie-01;
    padding: 3px 8px;
    border-radius: 100px;
    white-space: nowrap;
    &-group{
        @include flex($ai:center);
        .chip{
            + .chip{
                margin-left: 8px;
            }
        }
    }
    .button-icon{
        margin-left: 8px;
        padding: 0;
        color: $color-neutral-01;
        i[class^='icon-']{
            font-size: 16px;
        }
        &:hover{
            background-color: transparent;
        }
    }

}