.sidebar{
    @include flex($fd:column);
    @include flex-child($fs:0);
    @include position ($p: relative);
    background-clip: padding-box;
    width: 275px;
    height: 100%;
    padding: 24px 24px 24px 16px;
    border-right: 1px solid $color-neutral-04;
    transition: width 0.15s ease-in-out;

    //Collapsed/Expanded button
    &__button{
        @include position ($p: absolute, $t:24px, $r: -14px);
        .button-icon{
            border-radius: 100px;
            border: 1px solid $color-neutral-04;
            background-color: $color-neutral-01;
            transition: all .15s ease-in-out;
            i[class^='icon-']{
                font-size:16px;
                color:$color-neutral-05;
            }
        }
    }
    
    &__body{
        @include flex-child($fg:2);
        .button{
            width: 100%;
            height: 40px;
            transition: width 0.15s ease-in-out , color .15s ease-in-out .20s, background-color .15s ease-in-out;;
            overflow: hidden;
            i[class^='icon-']{
                margin-right: 2px;
                margin-left: 0;
                transition: all .15s ease-in-out;
            }
        }
        &__title{
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 12px;
        }
        &__options{
            padding-top: 24px;
            padding-left: 0;
            margin: 0;
            list-style: none;
            .options{
                &__option{
                    + .options__option{
                        margin-top: 8px;
                    }

                    &__link{
                        padding: 8px;
                        color: $color-neutral-07;
                        transition: all .15s ease-in-out;
                        &--active{
                            background-color: $color-neutral-02;
                            transition: all .15s ease-in-out;
                            border-radius: 8px;
                            font-weight: 700;
                            color: $color-primary-cherry-pie-01;
                        }
                        i[class^='icon-']{
                            margin:0;
                            font-size: 16px;
                            transition: all .15s ease-in-out;
                        }
                        span{
                            @include flex-child($fg:2);
                            margin-left: 16px;
                            height: 25px;
                            width: 100%;
                            transition: opacity .15s ease-in-out .20s, width .15s ease-in-out;
                        }
                        &:hover{
                            color: $color-primary-cherry-pie-01;
                            transition: all .15s ease-in-out;
                        }
                        .badge{
                            @include position ($p: relative, $b:0px,  $r: 0px);
                            transition: all .15s ease-in-out ;
                        }
                        &[data-tooltip]{
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
                &__separator{
                    border-top: 1px solid $color-neutral-04;
                    display: block;
                    margin: 24px 0 32px 0;
                }
            }
            > .button-icon{
                opacity: 0;
                padding: 0 8px;
                transition: opacity .15s ease-in-out .15s;
                i[class^='icon-']{
                   color: $color-neutral-07;
                   font-size: 0;
                   transition: none;
                }
                &:hover:not(disabled){
                    background-color: transparent;
                    i[class^='icon-']{
                        color: $color-primary-cherry-pie-01;
                    }
                }
            }
        }
        &__link{
            font-size: 14px;
            line-height: 18px;
            margin-top: 8px;
            padding-left: 8px;
            + .sidebar__body__title{
                margin-top: 32px;
            }
        }

        .tile{
            padding: 16px;
            opacity: 1;
            transition: opacity .15s ease-in-out .20s;
        }
    }

    //COLLAPSED
    &--collapsed{
        width: 88px;
        padding: 16px 24px;
        transition: width 0.15s ease-in-out;
        .sidebar{
            &__button{
                transform: rotate(180deg);
                transition: all .15s ease-in-out;
            }
            &__body{
                .button{
                    font-size: 0;
                    padding: 0;
                    width: 40px;
                    height: 40px;
                    color: $color-primary-purple-01;
                    transition: width 0.15s ease-in-out ,color .15s ease-in-out .20s;
                    i[class^='icon-']{
                        font-size:24px;
                        margin-right: 0;
                        color: $color-neutral-01;
                        transition: all .15s ease-in-out;
                    }
                }
                &__options{
                    .options{
                        &__option{
                            &__link{
                                i[class^='icon-']{
                                    font-size: 24px;
                                    transition: all .15s ease-in-out;
                                }
                                span{
                                    opacity: 0;
                                    margin-left: 0;
                                    width: 0;
                                    transition: opacity .05s ease-in-out, width .05s ease-in-out, margin-left .15s ease-in-out .05s;
                                }
                                .badge{
                                    @include position ($p: relative, $b:-10px,  $r: 4px);
                                    transition: all .15s ease-in-out;
                                }
                                &[data-tooltip]{
                                    &:before{
                                        display: block;
                                        @include position ($p: absolute, $t: 50%, $b:inherit, $r:inherit,$l:48px);
                                        white-space: normal;    
                                        transform: translatey( -50%);   
                                        max-width: 350px;
                                        width: max-content;   
                                    }
                                }
                            }
                        }
                    }
                    > .button-icon{
                        opacity: 1;
                        width: 40px;
                        padding: 8;
                        transition: opacity .15s ease-in-out .05s;
                        i[class^='icon-']{
                            font-size: 24px;
                            transition: none;
                         }
                        &[data-tooltip]{
                            &:before{
                                @include position ($p: absolute, $t: 50%, $b:inherit, $r:inherit,$l:48px);
                                white-space: normal;    
                                transform: translatey( -50%);   
                                max-width: 350px;
                                width: max-content;   
                            }
                        }
                    }
                    .tile{
                        opacity: 0;
                        height: 0;
                        transition: none;
                    }
                } 
            }
        }
    }

    //SIDEBAR TEMLATE PAGE
    &--template{
        padding: 0 16px 0 0 ;
        .sidebar{
            &__body{
                &__options{
                    padding-top: 0;
                    .options{
                        &__option{
                            + .options__option {
                                margin-top: 0px;
                            }
                            &__link{
                                padding: 8px 12px;
                                &--active{
                                    background-color: $color-neutral-03;
                                    font-weight: 400;
                                    .tag{
                                        background-color: $color-neutral-01;
                                        color: $color-primary-purple-01;
                                    }
                                }
                                span{
                                    margin: 0;
                                }
                                .dropdown{
                                    opacity: 0;
                                    transition: all .15s ease-in-out .15s;
                                    &__trigger{
                                        padding: 0;
                                        margin-right: 8px;
                                        i[class^='icon-']{
                                            color:$color-neutral-06;
                                            font-size: 16px;
                                        }
                                    }
                                    &__menu{
                                        top:24px;
                                        &__item{
                                            i[class^='icon-']{
                                                font-size: 16px;
                                                margin-right: 8px;
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    .dropdown{
                                        opacity: 1;
                                        transition: all .15s ease-in-out .15s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //SKELETON
        &--skeleton{
            .sidebar{
                &__body{
                    &__title{
                        font-size: 0;
                        line-height: 0;
                        &:before{
                            content: '';
                            display: block;
                            width: 130px;
                            height: 24px;
                            @extend %skeleton;
                        }
                    }
                    &__options{
                        .options{
                            &__option{
                                &__link{
                                    min-height: 41px;
                                    @include flex($jc:space-between);
                                    padding-left: 0;
                                    &:before{
                                        content: '';
                                        @include flex-child($fg:2);
                                        display: block;
                                        height: 16px;
                                        @extend %skeleton;
                                    }
                                    &:after{
                                        content: '';
                                        display: block;
                                        @include flex-child($fs:0);
                                        width: 24px;
                                        height: 16px;
                                        margin-left: 16px;
                                        @extend %skeleton;
                                    }
                                    > * {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    &__link{
                        padding-left: 0;
                        &:before{
                            content: '';
                            display: block;
                            width: 100px;
                            height: 16px;
                            @extend %skeleton;
                        }
                        > * {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}