
.workspace-toolbar{
    @include flex($jc:space-between);
    @include position ($p: relative);
    background-color: $color-neutral-01;
    box-shadow:  0 -64px 60px 24px $color-neutral-07;
    border-bottom: 1px solid $color-neutral-04;
    padding: 4px 24px;
    z-index: 100;
    &__left,
    &__center,
    &__right{
        @include flex($ai:center);
    }
    &__left{
        button + button{
            margin-left: 8px;
        }
    }
    &__center{
        @include flex($jc:center);
        @include flex-child($fg:2);
        button{
            + .dropdown{
                margin-left: 16px;
            }
            + button{
                margin-left: 16px;
            }
        } 
        .dropdown{
            margin-left: 16px;
            .button-icon{
                &:hover:not(:disabled){
                    background-color: $color-neutral-03;
                }
                .icon-chevron-down{
                    color: $color-primary-cherry-pie-01;
                    margin-left: 0;
                }
            }
            &--open{
                .button-icon{
                    background-color: $color-neutral-03;
                    .icon-chevron-down{
                        transform: rotate(-180deg);
                    }
                }
            }
            &__trigger{
                &.button-icon{
                    + .dropdown__menu{
                        min-width: 203px;
                    }
                }
            }

            + button{
                margin-left: 16px;
            }
        }
    }
    &__right{
        .input-group{
            &--collapsed-input{
                width: 120px;
                .input{
                    padding: 3px 16px 3px 26px;
                    min-height: 24px;
                    height: 24px;
                }
                i[class^='icon-']{
                    top: 4px;
                    left: 8px;
                    color: $color-neutral-05;
                }
            }
        } 
        &__separator{
            border-left: 1px solid $color-neutral-04;
            margin: 0 32px;
            height: 65%;
        }
        &__members{
            @include flex($ai:center);
            .avatar{
                width: 32px;
                height: 32px;
                span{
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            .button{
                margin-left: 4px;
                border-color: $color-primary-purple-02;
                color: $color-primary-purple-02;
                padding: 0 8px;
                &:hover:not(:disabled){
                    background-color: $color-neutral-03;
                    border-color: $color-neutral-03;
                    color: $color-primary-purple-02;
                }
            }
        }
    }
}