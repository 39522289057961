// Font-size
$font-family: 'proxima-nova', sans-serif;
$font-size-16: 16px;

// Primary Colors
$color-primary-purple-01: #7756DB;
$color-primary-purple-02: #AB84FF;
$color-primary-purple-03: #412BA8;

$color-primary-carnation-01: #F43B4A;
$color-primary-carnation-02: #FF7376;
$color-primary-carnation-03: #BA0022;

$color-primary-cherry-pie-01: #230243;
$color-primary-cherry-pie-02: #4F2A6F;
$color-primary-cherry-pie-03: #04001E;

$color-primary-java-01: #18d6c8;
$color-primary-java-02: #6AFFFB;
$color-primary-java-03: #00A497;

//Neutral Colors
$color-neutral-01: #FFFFFF;
$color-neutral-02: #FAF8FA;
$color-neutral-03: #F3EFFC;
$color-neutral-04: #EAE8F0;
$color-neutral-05: #C9C5D1;
$color-neutral-06: #96939E;
$color-neutral-07: #63606A;
$color-neutral-08: #120F18;

//Accent
$color-accent-green-01: #006130;
$color-accent-green-02: #3CB372;

$color-accent-orange-01: #EE3E03;
$color-accent-orange-02: #F75F00;
$color-accent-orange-03: #FFB048;

$color-accent-yellow-01: #FFDB12;

$color-accent-pink-01: #F23E7D;
$color-accent-pink-02: #8E0753;

//Feedback
$color-feedback-blue: #D5F5F3;
$color-feedback-green: #E5F6EC;
$color-feedback-orange: #FFF2DF;
$color-feedback-yellow: #FFFEE6;
$color-feedback-pink: #FFE9EE;
$color-feedback-purple: #EDE7FA;