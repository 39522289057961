.input{
    @include flex($ai:center);
    width: 100%;
    font-family: $font-family;
    background-color: $color-neutral-01;
    border: 1px solid $color-neutral-04;
    background-clip: padding-box;
    color: $color-neutral-07;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    resize: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    min-height: 40px;

    &-group{
        @include flex($fd:column);
        width: 100%;
        label:not(.checkbox){
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: 700;
            line-height: 18px;
            color: $color-primary-cherry-pie-01;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }   

        &__button{
            @include flex($ai:center, $jc:space-between);
            .input{
                @include flex-child($fg:2);
                max-width: none;
            }
            .button-icon{
                min-height: 40px;
                min-width: 40px;
                margin-left: 8px;
            }
        }

        //Collapsed input
        &--collapsed-input{
            @include position ($p: relative);
            width: 100px;
            transition: width .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            i[class^='icon-']{
                @include position ($p: absolute, $l:14px, $t:12px);
            }
            .input{
                height: 40px;
                border-radius: 100px;
                padding-left: 36px;
                max-width: 100%;
            }
            &--expanded{
                transition: width .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                width: 400px;
            }
        }

        &__error-message{    
            color: $color-primary-carnation-01;
            font-size: 12px;
            line-height: 16px;
        }
    }
    
    &:disabled{
        pointer-events: none;
        background-color: $color-neutral-02;
        border-color: $color-neutral-04;
        color: $color-neutral-06;
    }

    &::placeholder {
        color: $color-neutral-06;
        opacity: 1;
    }

    &:focus:not(.dropdown__trigger){
        outline: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-color: $color-primary-cherry-pie-01;
    } 

    &--error,
    &--error:focus:not(.dropdown__trigger){
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-color: $color-primary-carnation-01;
    }
}