@import url("https://p.typekit.net/p.css?s=1&k=ucu2eff&ht=tk&f=139.140.175.176&a=80019407&app=typekit&e=css");

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

//Icons
@font-face {
  font-family: 'tecalis-icons';
  src: url('../fonts/icons-tecalis.eot?9ky28y');
  src: url('../fonts/icons-tecalis.eot?9ky28y#iefix') format('embedded-opentype'),
    url('../fonts/icons-tecalis.ttf?9ky28y') format('truetype'),
    url('../fonts/icons-tecalis.woff?9ky28y') format('woff'),
    url('../fonts/icons-tecalis.svg?9ky28y#icons-tecalis') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'tecalis-icons' !important;
  speak-as: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //icon size
  &.i-xs {
    font-size: 16px;
  }

  &.i-sm {
    font-size: 24px;
  }

  &.i-md {
    font-size: 32px;
  }

  &.i-lg {
    font-size: 40px;
  }

  &.i-xl {
    font-size: 64px;
  }
}

.icon-steady-page1:before {
  content: "\e95a";

}

.icon-share:before {
  content: "\e95b";

}

.icon-comment-list:before {
  content: "\e95c";

}

.icon-add-comment:before {
  content: "\e95d";

}

.icon-arrow-up:before {
  content: "\e95e";

}

.icon-execution-error:before {
  content: "\e95f";

}

.icon-pending-check:before {
  content: "\e960";

}

.icon-minus:before {
  content: "\e959";

}

.icon-list:before {
  content: "\e91d";

}

.icon-alert-triangle:before {
  content: "\e91e";

}

.icon-chevron-left:before {
  content: "\e91c";

}

.icon-star-filled:before {
  content: "\e91b";

}

.icon-activity:before {
  content: "\e900";

}

.icon-alert:before {
  content: "\e901";

}

.icon-arrow-double:before {
  content: "\e902";

}

.icon-arrow-down:before {
  content: "\e903";

}

.icon-arrow-left:before {
  content: "\e904";

}

.icon-arrow-right:before {
  content: "\e905";

}

.icon-back-ajax-result:before {
  content: "\e906";

}

.icon-bell:before {
  content: "\e907";

}

.icon-book-open:before {
  content: "\e908";

}

.icon-bulb:before {
  content: "\e909";

}

.icon-calendar:before {
  content: "\e90a";

}

.icon-capture-opened-window:before {
  content: "\e90b";

}

.icon-capture-popup:before {
  content: "\e90c";

}

.icon-change-user-agent:before {
  content: "\e90d";

}

.icon-change-window:before {
  content: "\e90e";

}

.icon-check-circle:before {
  content: "\e90f";

}

.icon-check:before {
  content: "\e910";

}

.icon-chevron-down:before {
  content: "\e911";

}

.icon-chevron-right:before {
  content: "\e912";

}

.icon-clic-coordenates:before {
  content: "\e913";

}

.icon-click:before {
  content: "\e914";

}

.icon-clipboard:before {
  content: "\e915";

}

.icon-clock:before {
  content: "\e916";

}

.icon-close-window:before {
  content: "\e917";

}

.icon-close:before {
  content: "\e918";

}

.icon-columns:before {
  content: "\e919";

}

.icon-conection:before {
  content: "\e91a";

}

.icon-copy:before {
  content: "\e91f";

}

.icon-corner-up-left:before {
  content: "\e920";

}

.icon-corner-up-right:before {
  content: "\e921";

}

.icon-database:before {
  content: "\e922";

}

.icon-double-click:before {
  content: "\e923";

}

.icon-download-file:before {
  content: "\e924";

}

.icon-download:before {
  content: "\e925";

}

.icon-edit-2:before {
  content: "\e926";

}

.icon-evaluate-js:before {
  content: "\e927";

}

.icon-extract-data:before {
  content: "\e928";

}

.icon-eye-off:before {
  content: "\e929";

}

.icon-eye:before {
  content: "\e92a";

}

.icon-file:before {
  content: "\e92b";

}

.icon-fill-form:before {
  content: "\e92c";

}

.icon-filter:before {
  content: "\e92d";

}

.icon-folder:before {
  content: "\e92e";

}

.icon-group:before {
  content: "\e92f";

}

.icon-hand:before {
  content: "\e930";

}

.icon-help-circle:before {
  content: "\e931";

}

.icon-home:before {
  content: "\e932";

}

.icon-info:before {
  content: "\e933";

}

.icon-inject-js:before {
  content: "\e934";

}

.icon-intercept-ajax:before {
  content: "\e935";

}

.icon-layout:before {
  content: "\e936";

}

.icon-level-0:before {
  content: "\e937";

}

.icon-log-out:before {
  content: "\e938";

}

.icon-maximize:before {
  content: "\e939";

}

.icon-menu:before {
  content: "\e93a";

}

.icon-message-circle:before {
  content: "\e93b";

}

.icon-message-square:before {
  content: "\e93c";

}

.icon-minimize:before {
  content: "\e93d";

}

.icon-more:before {
  content: "\e93e";

}

.icon-move:before {
  content: "\e93f";

}

.icon-open-web:before {
  content: "\e940";

}

.icon-package:before {
  content: "\e941";

}

.icon-pause:before {
  content: "\e942";

}

.icon-play:before {
  content: "\e943";

}

.icon-plus:before {
  content: "\e944";

}

.icon-refresh:before {
  content: "\e945";

}

.icon-resize-viewport:before {
  content: "\e946";

}

.icon-return-nav-state:before {
  content: "\e947";

}

.icon-rocket:before {
  content: "\e948";

}

.icon-scissors:before {
  content: "\e949";

}

.icon-scroll:before {
  content: "\e94a";

}

.icon-search:before {
  content: "\e94b";

}

.icon-selector:before {
  content: "\e94c";

}

.icon-settings:before {
  content: "\e94d";

}

.icon-star:before {
  content: "\e94e";

}

.icon-steady-page:before {
  content: "\e94f";

}

.icon-trash:before {
  content: "\e950";

}

.icon-trending-down:before {
  content: "\e951";

}

.icon-trending-up:before {
  content: "\e952";

}

.icon-ungroup:before {
  content: "\e953";

}

.icon-upload-file:before {
  content: "\e954";

}

.icon-upload-form:before {
  content: "\e955";

}

.icon-upload:before {
  content: "\e956";

}

.icon-wait-element:before {
  content: "\e957";

}

.icon-workflow:before {
  content: "\e958";

}