.alert{
    @include flex($ai:center);
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    &__header{
        @include flex($ai:center, $jc:space-between);
        @include flex-child($fg:2);
        padding: 10px 14px 10px 18px;
        background-color: $color-neutral-02;
        .alice{
            margin: 0;     
        }
        &__icon{
            @include flex($ai:center, $jc:center);
            background-color: rgba(24, 214, 201, 0.2);
            border-radius: 100%;
            width: 24px;
            height: 24px;
            i[class^='icon-']{
                font-size: 16px;
                @include flex-child($fs:0);
                color: $color-primary-java-01;
            }
        }
        &__text{
            @include flex-child($fg:2);
            padding: 0 16px;
            line-height: 24px;
        }
    }
    &__body{
        width: 100%;
        padding: 16px;
        background-color: $color-neutral-01;
        border: 1px solid $color-neutral-04;
        border-top: none;
        border-radius: 0 0 8px 8px;
        color: $color-neutral-07;
        &__actions{
            @include flex($ai:flex-end, $jc:space-between);
            margin-top: 16px;
            .button-icon{
                padding: 0;
                i[class^='icon-']{
                    color: $color-neutral-06;
                }
                &:hover{
                    background-color: transparent;
                }
            }
        }
        > .button{
            margin-top: 16px;
        }
    }
  
    //ERROR
    &--error{
        font-size: 14px;
        .alert{
            &__header{
                background-color: $color-feedback-pink;
                padding: 8px 8px 8px 20px;
                &__text{
                    b{
                        color: $color-primary-carnation-01;
                    }
                }
            }
        }
    }

    &--expanded{
        @include flex($fd:column,$ai:flex-start);
        .alert{
            &__header{
                padding: 16px 8px 16px 20px;
                width: 100%;
                &__text{
                    padding: 0 16px;
                    b{
                        color: $color-primary-carnation-01;
                    }
                }
            }
            
        }
    }

    //FIRST-STEPS
    &--first-steps{
        @include flex($jc:space-between);
        padding: 40px;
        background-color: $color-neutral-03;
        .alert{
            &__body{
                @include flex-child($fb:50%);
                padding: 0 40px 0 0 ;
                background-color: transparent;
                border: none;
                h5{
                    margin-bottom: 16px;
                    color: $color-primary-cherry-pie-01;
                }
                .list{
                    &__item{
                        @include flex($ai:center);
                        padding: 8px 16px;
                        border-radius: 8px;
                        background-color: $color-neutral-01;
                        border: 1px solid $color-neutral-04;
                        min-height: 40px;
                        .tag-icon{
                            width: 24px;
                            height: 24px;
                            border-radius: 100px;
                            font-size: 16px;
                            line-height: 18px;
                            color: $color-neutral-06;
                            border-color: $color-neutral-04;
                            margin-right: 10px;
                        }
                        &__text{
                            @include flex-child($fg:2);
                            color: $color-primary-cherry-pie-01;
                        }

                        &--done{
                            background-color: $color-neutral-02;
                            .tag-icon{
                                background-color: $color-primary-java-01;
                                border-color: $color-primary-java-01;
                                i[class^='icon-']{
                                    color: $color-neutral-01;
                                    font-size: 16px;
                                }
                            }
                            .list{
                                &__item{
                                    &__text{
                                        color: $color-neutral-07;
                                    }
                                }
                            }
                        }

                        + .list__item{
                            margin-top: 8px;
                        }
                    }
                }
            }
            &__action{
                @include flex-child($fb:50%);
                @include flex($ai:center);
                padding: 0 24px 0 0px ;
                &__image{
                    @include flex-child($fs:0);
                    margin-right: 24px;
                }
                .button{
                    margin: 24px 0;
                }
            }
        }
        .button-icon{
            @include flex-child($as:flex-start);
            padding: 0;
            i[class^='icon-']{
                color: #96939E;
            }
        }
        // Rafa
        app-button-icon{
            @include flex-child($as:flex-start);
            padding: 0;
            i[class^='icon-']{
                color: #96939E;
            }
        }
    }
}