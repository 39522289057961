.tabs{
    @include flex();
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    color: $color-neutral-07;
    border-bottom: 1px solid $color-neutral-04;
    .tabs{
        &__tab{
            @include flex($ai:center, $jc:center);
            cursor: pointer;
            white-space: nowrap;
            border-bottom: 3px solid transparent;
            + .tabs__tab{
               margin-left: 32px;
            }
            .badge{
                margin-left: 4px;
            }
            &--active{
               color: $color-primary-cherry-pie-01;
               border-bottom: 3px solid $color-primary-carnation-01;
            }
        }
    }

    //PILLS
    &--pills{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 24px;
        border-bottom: none;
        .tabs{
            &__tab{
                border: 1px solid $color-neutral-04;
                border-radius: 8px;
                padding: 8px 16px;
                i[class^='icon-']{
                    font-size: 24px;
                    color:$color-neutral-05;
                    padding-left:16px;
                    padding-right: 6px;
                    transition: all .15s ease-in-out;
                }
                + .tabs__tab{
                    margin-left: 8px;
                }
                &--active{
                    background-color: $color-primary-cherry-pie-01;
                    border-color: $color-primary-cherry-pie-01;
                    color: $color-neutral-01;
                    pointer-events: none;
                }
            }
        }
    }

    //VERTICAL PILLS
    &--vertical{
        @include flex($fd:column);
        .tabs{
            &__tab{
                @include flex($jc:space-between);
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                padding: 16px;
                color: $color-primary-cherry-pie-01;
                transition: background-color .15s ease-in-out;
                &:hover:not(.tabs__tab--active){
                    transition: background-color .15s ease-in-out;
                    background-color: $color-neutral-03;
                    i[class^='icon-']{
                        padding-right: 0px;
                        transition: all .15s ease-in-out;
                    }
                }
                &--active{
                    color: $color-neutral-01;
                }
                + .tabs__tab{
                    margin-left: 0px;
                    margin-top: 16px;
                }
            }
        }
    }
}