.pagination{
    @include flex($ai:center, $jc:space-between);
    margin-top: 80px;
    padding: 8px 0;
    &__modal {
        margin-top: 10px;
    }
    &__navigation{
        @include flex($ai:center);
        .button-icon{
            color: $color-neutral-06;
        
            &:disabled{
                pointer-events: none;
                color: $color-neutral-04;
            }
        }
        &__pages{
            @include flex($ai:center);
            padding: 0 24px;
            app-button{
                + app-button{
                    margin-left: 8px;
                }
            }
            .pages{
                &__page{
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-neutral-06;
                    font-weight: 400;
                    padding: 0 8px;
                    background-color: transparent;
                    min-width: 25px;
                    &--current{
                        background-color: $color-primary-cherry-pie-01;
                        color: $color-neutral-01;
                    }
                    &:hover:not(.pages__page--current){
                        background-color: transparent;
                        color: $color-neutral-06;
                    }
                    + .pages__page{
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    &__go{
        @include flex($ai:center);
        span{
            color: $color-neutral-06;
            font-size: 14px;
            line-height: 18px;
            margin-right: 8px;
        }
        .input{
            max-width: 128px;
        }
    }
    
}