.progress{
    @include position ($p: relative);
    height: 4px;
    background-color: $color-neutral-04;
    border-radius: 100px;
    margin-bottom: 8px;
    &__bar{
        @include position ($p: absolute, $t:0, $b:0);
        background-color: $color-primary-purple-02;
        border-radius: 100px;
    }

    //COLORS
    &--green{
        .progress{
            &__bar{
                background-color: $color-primary-java-01;
            }
        }
    }
    &--orange{
        .progress{
            &__bar{
                background-color: $color-accent-orange-02;
            }
        }
    }
    &--red{
        .progress{
            &__bar{
                background-color: $color-primary-carnation-01;
            }
        }
    }

    //SIZE
    &--big{
        height: 8px;
    }
}