//font-size
.fs{
    &-24{
        font-size: 24px;
        line-height: 32px;
    }
    &-18{
        font-size: 18px;
        line-height: 26px;
    }
    &-16{
        font-size: 16px;
        line-height: 24px;
    }
    &-14{
        font-size: 14px;
        line-height: 22px;
    }
    &-12{
        font-size: 12px;
        line-height: 16px;
    }
}

//flex
.flex{
    @include flex();
    &.ai-center{
        align-items: center;
        -ms-align-items: center;
        -webkit-align-items: center;
    }
    &.ai-flex-start{
        align-items: flex-start;
        -ms-align-items: flex-start;
        -webkit-align-items: flex-start;
    }
    &.ai-flex-end{
        align-items: flex-end;
        -ms-align-items: flex-end;
        -webkit-align-items: flex-end;
    }
    &.fw-wrap{
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }
    &.jc-space-between{
        justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
    }
    &.jc-flex-start{
        justify-content: flex-start;
        -ms-justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }
    &.jc-center{
        justify-content: center;
        -ms-justify-content: center;
        -webkit-justify-content: center;
    }
    &.fd-column{
        flex-direction: column;
        -webkit-flex-direction: column;
    }
}
.fg-2{
    flex-grow: 2;
    -ms-flex-grow: 2;
    -webkit-flex-grow: 2;
}
.fs-0{
    flex-shrink: 0;
    -ms-flex-shrink:0;
    -webkit-flex-shrink:0;
}

//margin 
//top
.m-t-4{
    margin-top: 4px;
}
.m-t-8{
    margin-top: 8px;
}
.m-t-12{
    margin-top: 12px;
}
.m-t-14{
    margin-top: 14px;
}
.m-t-16{
    margin-top: 16px;
}
.m-t-18{
    margin-top: 18px;
}
.m-t-20{
    margin-top: 20px;
}
.m-t-24{
    margin-top: 24px;
}
.m-t-32{
    margin-top: 32px;
}
.m-t-40{
    margin-top: 40px;
}
.m-t-64{
    margin-top: 64px;
}
.m-t-80{
    margin-top: 80px;
}

//left
.m-l-4{
    margin-left: 4px;
}
.m-l-8{
    margin-left: 8px;
}
.m-l-12{
    margin-left: 12px;
}
.m-l-16{
    margin-left: 16px;
}
.m-l-24{
    margin-left: 24px;
}
.m-l-32{
    margin-left: 64px;
}
.m-l-40{
    margin-left: 40px;
}
.m-l-64{
    margin-left: 64px;
}
.m-l-80{
    margin-left: 80px;
}
//bottom
.m-b-4{
    margin-bottom: 4px;
}
.m-b-8{
    margin-bottom: 8px;
}
.m-b-12{
    margin-bottom: 12px;
}
.m-b-16{
    margin-bottom: 16px;
}
.m-b-24{
    margin-bottom: 24px;
}
.m-b-32{
    margin-bottom: 32px;
}
.m-b-40{
    margin-bottom: 40px;
}
.m-b-64{
    margin-bottom: 64px;
}
.m-b-80{
    margin-bottom: 80px;
}
//Right
.m-r-4{
    margin-right: 4px;
}
.m-r-6{
    margin-right: 6px;
}
.m-r-8{
    margin-right: 8px;
}
.m-r-10{
    margin-right: 10px;
}
.m-r-12{
    margin-right: 12px;
}
.m-r-16{
    margin-right: 16px;
}
.m-r-24{
    margin-right: 24px;
}
.m-r-32{
    margin-right: 32px;
}
.m-r-40{
    margin-right: 40px;
}
.m-r-64{
    margin-right: 64px;
}
.m-r-80{
    margin-right: 80px;
}

//TEXT COLOR
.text{
    &--gray-light{
        color: $color-neutral-05;
    }
    &--gray{
        color: $color-neutral-06;
    }
    &--gray-dark{
        color: $color-neutral-07;
    }
    &--purple{
        color: $color-primary-purple-01;
    }
    &--purple-02{
        color: $color-primary-purple-02;
    }
    &--red{
        color: $color-primary-carnation-01;
    }
    &--red-light{
        color: $color-primary-carnation-02;
    }
    &--green{
        color: $color-primary-java-01;
    }
    &--orange{
        color: $color-accent-orange-02;
    }
    &--orange-light{
        color:$color-accent-orange-03;
    }
}