html{
    body{
        .container{
            //WORKSPACE
            &--workspace{
                @include position ($p: relative);
                background-color: $color-neutral-02;
                height: calc(100vh - 72px);
                width: 100%;
                overflow-y: hidden;
                .content{
                    &--workspace{
                        width: 100%;
                        overflow-y: hidden;
                        .workspace{
                            &__workspace{
                                padding-right: 56px;
                                &__zoom{
                                    @include position ($p: absolute, $b:54px, $r:82px);
                                    box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.05), 0px 12px 24px rgba(0, 0, 0, 0.05);
                                    border-radius: 8px;
                                    background-color: $color-neutral-01;
                                    overflow: hidden;
                                    .button-icon{
                                        border-radius: 0;
                                        i[class^='icon-']{
                                            color:$color-neutral-05;
                                        }
                                    }
                                    .button-icon,
                                    app-button-icon{
                                        + .button-icon,
                                        + app-button-icon{
                                            .button-icon{
                                                border-top: 1px solid $color-neutral-04;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}