.onboarding{
    @include position ($p: fixed, $t:0, $b:0, $l:0, $r:0);
    background-color: $color-primary-cherry-pie-01;
    z-index: 10000;
    @include flex($fd:column, $ai:center, $jc:center);
    .alice,
    app-alice{
        margin: 0 auto;
        + h1{
            margin-top: 76px;
        }
    }
    h1{
        color: $color-neutral-01;
        animation: 2s anim-lineUp ease-out;
        transition: all .15s ease-in-out;
        .underline-item{ 
            display: inline-block;
            padding-bottom:6px;
            background-image: linear-gradient($color-primary-purple-02 0 0);
            background-position: 0 100%; 
            background-size: 0% 6px;
            background-repeat: no-repeat;
            transition:background-size 0.3s,
            background-position 0s 0.3s;
            &__underline{
                background-position: 100% 100%; 
                background-size: 100% 6px;
            }
        }
        opacity: 0;
        transform: translateY(30px);
        animation: fadeIn 0.5s ease-out forwards;
        display:inline-block;
        &.fadeIn:nth-of-type(2){
          animation-delay: 1s;
        }
    }
    .button{
        margin-top: 64px;
        transition: all .15s ease-in-out;
    }
}
@keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  } 

  
@keyframes fadeIn{
  to{
    opacity: 1;
    transform: translateY(0);
  }
}