.badge {
    @include flex($ai:center, $jc:center);
    @include flex-child($fs:0);
    background-color: $color-primary-carnation-01;
    border: none;
    font-size: 12px;
    color:$color-neutral-01;
    border-radius: 100px;
    padding: 0;
    width: 18px;
    height: 18px;
    font-weight: 800;
    &--warning{
        background-color: $color-accent-orange-03;
    }
    &--error{
        background-color: $color-accent-orange-02;
        font-size: 16px;
    }
    &--small{
        width: 8px;
        height: 8px;
    }
}