@media (max-width: 1200px) {
    html{
        body{

        }
    }
}
@media (max-width: 992px) {
    html{
        body{
        
        }
    }
}
@media (max-width: 768px) {
    html{
        body{
            
        }
    }
}
@media (max-width: 576px) {
    html{
        body{
            .row{
                .col-2,
                .col-3,
                .col-4,
                .col-6{
                    width: 100%;
                }
            } 
        }
    }
}


