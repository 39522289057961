.button{
    @include flex($ai:center, $jc:center);
    background-color: $color-primary-purple-01;
    border: 1px solid transparent;
    border-radius: 8px;
    font-family: $font-family;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $color-neutral-01;
    padding: 10px 24px;
    cursor: pointer;
    transition: all .15s ease-in-out;
    white-space: nowrap;
    
    &:hover:not(disabled){
        background-color: $color-primary-cherry-pie-01;
        transition: all .15s ease-in-out;
    }
    
    i[class^='icon-']{
        font-size: 16px;
        margin-left: 8px;
    }

    &:disabled{
        background-color: $color-neutral-04;
        pointer-events: none;
    }

    &--ghost{
        &:hover:not(disabled){
            background-color: $color-primary-purple-02;
            transition: all .15s ease-in-out;
        }
    }

    //SECONDARY
    &--secondary{
        background-color: transparent;
        color: $color-primary-cherry-pie-01;
        border: 1px solid $color-primary-cherry-pie-01;
        &:hover:not(disabled){
            background-color: $color-primary-cherry-pie-01;
            color: $color-neutral-01;
            transition: all .15s ease-in-out;
        }
        &:disabled{
            background-color: transparent;
            border-color: $color-neutral-05;
            color: $color-neutral-05;
            pointer-events: none;
        }
        &--ghost{
            border-color: $color-neutral-01;
            color: $color-neutral-01;
            &:hover:not(disabled){
                background-color: $color-primary-purple-02;
                border-color: $color-primary-purple-02;
                transition: all .15s ease-in-out;
            }
        }
    }

    //SOFT
    &--soft{
        border: 1px solid $color-neutral-04;
        color: $color-neutral-06;
        background-color: transparent;
        font-weight: 400;
        i[class^='icon-']{
            font-size: 24px;
            transition: all .15s ease-in-out;
            @include position ($p: relative, $l: -4px);
        }
        &:hover:not(disabled){
            background-color: transparent;
            i[class^='icon-']{
                transition: all .15s ease-in-out;
                @include position ($p: relative, $l: 0px);
            }
        }
        &:disabled{
            background-color: transparent;
            border-color:$color-neutral-04;
            color: $color-neutral-05;
        }
    }

    //TOGGLE
    &--toggle{
        padding: 8px 16px;
        border-color: $color-neutral-04;
        background-color: $color-neutral-01;
        color: $color-neutral-07;
        &:hover:not(disabled){
            background-color: $color-neutral-01;
        }
        &--toggled{
            background-color: $color-primary-cherry-pie-01;
            border-color: $color-primary-cherry-pie-01;
            color:  $color-neutral-01; 
            &:hover:not(disabled){
                background-color: $color-primary-cherry-pie-01;
                border-color: $color-primary-cherry-pie-01;
                color:  $color-neutral-01; 
            }
        }
        &__wrapper{
            @include flex($ai:center, $fw:wrap);
            margin: -4px;
            .button{
                margin: 4px;
            }
        }
    }

    //ICON
    &-icon{
        @include flex($ai:center, $jc:center);
        background-color: transparent;
        border: none;
        color: $color-primary-cherry-pie-01;
        padding: 4px;
        border-radius: 4px;
        transition: all .15s ease-in-out;
        cursor: pointer;
        i[class^='icon-']{
            font-size: 24px;
            transition: all .15s ease-in-out;
        }

        &:hover:not(disabled){
            background-color: $color-neutral-03;
            transition: all .15s ease-in-out;
        }
        
        &--selected{
            background-color: $color-primary-purple-01;
            color: $color-neutral-01;
            pointer-events: none;
        }
        &--warning{
            color: $color-accent-orange-03;
            @include position ($p: relative);
            .badge{
                background-color: $color-accent-orange-03;
                width: 16px;
                height: 16px;
                @include position ($p: absolute, $t:0px, $r:-1px);
            }
        }

        &:disabled{
            color: $color-neutral-06;
            pointer-events: none;
        }

        //CIRCLE
        &--circle{
            @include flex($ai:center, $jc:center);
            border-radius: 100px;
            border: 2px solid $color-neutral-05;
            width:40px;
            height:40px;
            i[class^='icon-']{
                color:$color-neutral-05;
                font-size:24px;
            }
            &--sm{
                width:24px;
                height:24px;
                i[class^='icon-']{
                    font-size:16px
                }
            }
            &:hover:not(:disabled){
                background-color: $color-primary-purple-01;
                border-color:$color-primary-purple-01;
                i[class^='icon-']{
                    color:$color-neutral-01;
                }
            }
            &:disabled{
                background-color: $color-neutral-04;
            }
        }
    }

    //SIZE
    &--lg{
        font-size: $font-size-16;
        line-height: 24px;
        padding: 16px 32px;
    }
    &--sm{
        padding: 8px 12px;
    }
    &--xs{
        padding: 3px 8px;
        font-weight: 400;
        border-radius: 4px;
    }
}