.alice{
    width: 12px;
    height: 12px;
    filter: blur(3px);
    box-shadow: 
        inset 5px 0px 5px $color-primary-carnation-01, 
        inset -5px -5px 2px $color-primary-purple-01, 
        0px 0px 5px $color-primary-purple-01, 
        0px 2px 5px $color-primary-carnation-01;
    animation: spin 4s linear infinite, aureo-xs 8s linear infinite;
    margin: auto;
    border-radius: 50%;
    background-color: $color-primary-carnation-01;
    @include flex-child($fs:0);
    &--pulse{
        animation: spin 4s linear infinite, aureo-xs 8s linear infinite, pulse--xs 1s ease-in-out infinite;
    }

    //S
    &--s{
        width: 25px;
        height: 25px;
        filter: blur(4px);
        animation: spin 6s linear infinite, aureo-s 8s linear infinite, long-pulse 30s ease-in-out infinite;
    }

    //SM
    &--sm{
        width: 40px;
        height: 40px;
        filter: blur(4px);
        animation: spin 6s linear infinite, aureo-sm 8s linear infinite, long-pulse 30s ease-in-out infinite;
    }

    //LARGE
    &--lg{
        width: 80px;
        height: 80px;
        filter: blur(6px);
        animation: spin 6s linear infinite, aureo-lg 8s linear infinite, long-pulse 30s ease-in-out infinite;
    }

    //BIG
    &--big{
        animation: spin 4s linear infinite, aureo 8s linear infinite, long-pulse 30s ease-in-out infinite;
        width: 200px;
        height: 200px;
        filter: blur(20px);
        &--pulse{
            animation: spin 4s linear infinite, aureo 8s linear infinite, pulse 2s ease-in-out infinite;
        }
    }
   
}


//ANIMATIONS
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes aureo {
    100% {
        box-shadow: inset 20px 40px 100px $color-primary-purple-01 inset -50px -40px 150px $color-primary-carnation-01, 4px 0px 50px $color-primary-carnation-01, -4px 0px 50px $color-primary-purple-01;
    }
    75% {
        box-shadow: inset 150px 40px 100px $color-primary-carnation-01, inset -70px -40px 150px $color-primary-purple-01, 4px 0px 50px $color-primary-purple-01, -4px 0px 50px $color-primary-carnation-01;
    }
    50% {
       box-shadow: inset 20px 40px 100px $color-primary-purple-01, inset 90px -100px 300px $color-primary-carnation-01, 4px 0px 50px $color-primary-carnation-01 , -4px 0px 50px $color-primary-purple-01;
    }
    25% {
        box-shadow: inset 20px 40px 100px $color-primary-carnation-01, inset -100px -90px 150px $color-primary-purple-01, 4px 0px 50px $color-primary-purple-01, -4px 0px 50px $color-primary-carnation-01;
    }
    0% {
        box-shadow: inset 20px 40px 100px $color-primary-purple-01 inset -50px -40px 150px $color-primary-carnation-01, 4px 0px 50px $color-primary-carnation-01, -4px 0px 50px $color-primary-purple-01;
    }
}

@keyframes aureo-xs {
    100% {
        box-shadow: inset 5px 0px 5px $color-primary-carnation-01, inset -5px -5px 2px $color-primary-purple-01, 0px 0px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    75% {
        box-shadow: inset 2px 0px 6px $color-primary-carnation-01, inset 8px 0px 6px $color-primary-purple-01, 0px 0px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;   
    }
    50% {
        box-shadow: inset 3px 0px 2px $color-primary-carnation-01, inset -2px -6px 1px $color-primary-purple-01, 0px 0px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    25% {
        box-shadow: inset 5px 0px 5px $color-primary-carnation-01, inset -5px -5px 2px $color-primary-purple-01, 0px 0px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    0% {
        box-shadow: inset 1px 0px 2px $color-primary-carnation-01, inset -6px -1px 6px $color-primary-purple-01, 0px 0px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
}

@keyframes aureo-sm {
    100% {
        box-shadow: inset 0px 0px 30px $color-primary-carnation-01, inset 0px 0px 20px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    75% {
        box-shadow: inset 0px -40px 30px $color-primary-carnation-01, inset 0px 0px 60px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    50% {
       box-shadow: inset 0px 20px 40px $color-primary-carnation-01, inset 50px 70px 70px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    25% {
        box-shadow: inset -50px 40px 30px $color-primary-carnation-01, inset 10px 30px 40px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    0% {
        box-shadow: inset 0px -10px 30px $color-primary-carnation-01, inset 0px 0px 20px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;    }
}

@keyframes aureo-lg {
    100% {
        box-shadow: inset 0px 0px 30px $color-primary-carnation-01, inset 0px 0px 20px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    75% {
        box-shadow: inset 0px -40px 30px $color-primary-carnation-01, inset 0px 0px 60px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    50% {
       box-shadow: inset -50px 20px 40px $color-primary-carnation-01, inset 50px 70px 40px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    25% {
        box-shadow: inset 0px 40px 30px $color-primary-carnation-01, inset 10px 0px 40px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;
    }
    0% {
        box-shadow: inset 0px -10px 30px $color-primary-carnation-01, inset 0px 0px 20px $color-primary-purple-01, 0px 1px 5px $color-primary-purple-01, 0px 2px 5px $color-primary-carnation-01;    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulse--xs {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes long-pulse {
    0% {
        transform: scale(1);
    }
    3% {
        transform: scale(1.1);
    }
    7% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}