.table {
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            th {
                font-size: 14px;
                text-transform: uppercase;
                line-height: 18px;
                color: $color-neutral-06;
                .table {
                    &__cell {
                        @include flex($ai: center, $jc: flex-start);
                        padding: 8px 16px;
                        &--align-right {
                            @include flex($jc: flex-end);
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            .table {
                &__cell {
                    @include flex($ai: center, $jc: flex-start);
                    padding: 16px;
                    color: $color-neutral-08;
                    &--tag{
                        @include flex($jc: flex-start);
                    }
                    &--column {
                        @include flex($fd: column, $ai: flex-start);
                    }

                    &--align-right {
                        @include flex($jc: flex-end);
                        span{
                            white-space: nowrap;
                        }
                    }

                    //ALICE
                    .alice {
                        margin: 0 24px 0 0;
                        &.alice-m-0 {
                            margin: 0px;
                        }
                    }

                   //AVATAR
                    .avatar {
                        width: 32px;
                        height: 32px;
                        &-group {
                            .avatar {
                                margin-right: -12px;
                                span{
                                    font-size: 12px;
                                    font-weight: 500;
                                }
                                &:only-child{
                                    margin-right: 0;
                                }
                                &__number {
                                    margin-left: 16px;
                                }
                            }
                        }
                    }

                    //BUTTON-ICON
                    .button-icon,
                    app-button-icon {
                        color: $color-neutral-05;
                        &:hover:not(disabled) {
                            background-color: transparent;
                        }
                        + .dropdown,
                        + app-dropdown{
                            margin-left: 16px;
                        }
                    }

                    //DROPDOWN
                    .dropdown {
                        &--open {
                            .dropdown {
                                &__menu {
                                    width: 200px;
                                    max-height: fit-content;
                                    top: 40px;
                                    right: 8px;
                                }
                            }
                        }
                    }

                    //TAG
                    .tag-icon{
                        + .flex.fd-column{
                            white-space: nowrap;
                        }
                    }
                }
            }

            + tr {
                border-top: 1px solid $color-neutral-04;
            }
        }
    }

    //TABLE CLIKCABLE
    &--clickable {
        tbody {
            tr {
                cursor: pointer;
                &:hover {
                    transition: all .15s ease-in-out;
                    background-color: $color-neutral-02;
                }
            }
        }
    }

    //SKELETON
    &--skeleton{
        &.table{
            tbody{
                tr{
                    td{
                        &:first-child{
                            .table__cell{
                                &:before{
                                    content: "";
                                    @include flex-child($fs:0);
                                    display: block;
                                    width: 32px;
                                    height: 32px;
                                    margin-right: 16px;
                                    border: none;
                                    @extend %skeleton;
                                    border-radius: 100px;
                                }
                            }
                        }
                        .table{
                            &__cell{
                                &:after{
                                    content: "";
                                    max-width: 250px;
                                    width: 100%;
                                    height: 16px;
                                    min-width: 32px;
                                    @extend %skeleton;
                                }
                            }
                        }
                        &:last-child{
                            .table__cell{
                                @include flex($jc:flex-end);
                                &:after{
                                    content: "";
                                    display: block;
                                    width: 32px;
                                    height: 32px;
                                    border: none;
                                    @extend %skeleton;
                                    border-radius: 100px;
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}