html{
    font-family: $font-family;
    font-size: $font-size-16;
    font-weight: 400;
    color: $color-primary-cherry-pie-01;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;

    body{
        @include flex($fd:column);
        margin: 0;
        height: 100vh;

        *::selection {
            color: $color-primary-cherry-pie-01;
            background: $color-neutral-03;
        }

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        .title-xl{
            font-size: 64px;
            font-weight: 700;
            line-height: 80px;
        }

        h6, .h6, 
        h5, .h5, 
        h4, .h4, 
        h3, .h3, 
        h2, .h2, 
        h1, .h1 {
            margin-top: 0;
            margin-bottom: 0;
        }

        h1, .h1 {
            font-size: 42px;
            font-weight: 700;
            line-height: 54px;
        }
        h2, .h2 {
            font-size: 36px;
            font-weight: 700;
            line-height: 46px;
        }
        h3, .h3 {
            font-size: 28px;
            font-weight: 700;
            line-height: 38px;
        }
        h4, .h4 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
        }
        h5, .h5 {
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
        }
        h6, .h6 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        a {
            @include flex($ai:center);
            color: $color-primary-purple-01;
            text-decoration: none;
            transition: color .15s ease-in-out;
            cursor: pointer;
            > i[class^='icon-']{
                margin-right:8px;
                font-size: 24px;
            }
            &:hover{
                color: $color-primary-purple-02;
                transition: color .15s ease-in-out;
            }
        }

        span{
            > * {
                display: inline-block;
            }
        }

        ol,
        ul {
            margin: 0 0 16px 0;
            padding-left: 20px;
        }

        ol ol,
        ul ul,
        ol ul,
        ul ol {
            margin-bottom: 0;
        }

        label:not(.rating__star, .switcher){
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: 700;
            line-height: 18px;
            color: #230243;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .hidden{
            max-height: 0;
            opacity: 0;
            margin: 0;
            transition: all 0.35s ease-in-out;
            overflow: hidden;
            &--show{
                transition: all 0.35s ease-in-out;
                max-height: 100vh;
                opacity: 1;
                margin: inherit;
                overflow: inherit;
            }
        }

        main{
            @include flex-child($fg:2);
        }
        .container{
            @include flex();
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            height: calc(100vh - 67px);
            overflow: auto;
            .sidebar-prov{
                border-right: 1px solid #EAE8F0;
                padding: 24px;
                @include flex-child($fs:0);
                width: 273px;
            }
            .content{
                @include flex-child($fg:2);
                padding: 24px;
                max-width: 1400px;
                margin: 0 auto;
                &__header-actions{
                    padding: 12px 0;
                    margin-bottom: 32px;
                }
                &__header-page{
                    @include flex($ai:center, $jc:space-between);
                    margin-bottom: 32px;
                    .breadcrumb{
                        margin-bottom: 0;
                    }
                    .tooltip{
                        &:before{
                            min-width: 330px;
                        }
                    }
                    &__actions{
                        @include flex($ai:center, $jc:flex-end);
                        .alert{
                            max-width: 410px;
                            margin-bottom: 0;
                            // rafa
                            &.no-width {
                                max-width: initial;
                            }
                        }
                        .button{
                            i[class^='icon-']{
                                margin-left: 0;
                                margin-right: 4px;
                            }
                        }
                    }
                }
                &__body{
                    &__section{
                        &--flex{
                            @include flex();
                        }
                    }
                    &__empty{
                        padding: 40px 0;
                        margin-top: 32px;
                        @include flex($fd:column, $ai:center);
                        img{
                            margin: 24px 0 16px 0;
                        }
                        h5{
                            color: $color-neutral-07;
                        }
                    }
                    &__see-more{
                        @include flex($jc:center);
                        margin-top: 80px;
                    }
                }

                //TEMPLATES PAGE
                &__flex{
                    @include flex($ai:flex-start);
                    .sidebar{
                        @include flex-child($fs:0);
                        margin-right: 64px;
                    }
                    &__contents{
                        @include flex-child($fg:2);
                        .content__body{
                            .card__flex-wrapper{
                                app-card-template{
                                    @include flex-child($fb:50%);
                                }
                            }
                        }
                    }
                }
            }
            
            //WORKSPACE
            &--workspace{
                @include position ($p: relative);
                background-color: $color-neutral-02;
                height: calc(100vh - 72px);
                .iframe{
                    &__workspace{
                        @include position ($p: fixed, $t:0, $r:0, $b:0,$l:0);
                        width: 100%;
                        height: 100%;
                        &__iframe{
                            width: 100%;
                            height: calc(100vh - 88px);
                            border: none;
                        }
                        &__bar{
                            background-color: $color-neutral-01;
                            padding: 24px;
                            box-shadow: 0px -12px 24px rgba(0, 0, 0, 0.05);
                            @include flex($ai:center, $jc:space-between);
                            .bar{
                                &__buttons{
                                    margin-left: 16px;
                                }
                            }
                        }
                    }
                }
            }

            //DEMOAREA
            &--demoarea{
                @include position ($p: relative);
                background-color: $color-neutral-02;
                height: 100%;
            }
        }
    }
}