html {
    body {
        .container {
            &--demoarea {
                .workspace-sidebar {
                    @include position ($p: relative, $t: inherit, $l: inherit);
                    &__header {
                        &__actions {
                            display: none;
                        }
                    }

                    &__body {
                        .collapse-action {
                            &__header {
                                &__action {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .content {
                    &--demoarea {
                        max-width: 100%;
                        width: 100%;
                        padding: 0;
                        .content{
                            &__header{  
                                @include flex($ai:center, $jc:space-between);
                                background-color: $color-neutral-01;
                                border-bottom: 1px solid $color-neutral-04;
                                padding: 16px 24px;
                                a {
                                    color: $color-neutral-06;
                                    app-icons{
                                        i[class^='icon-']{
                                            font-size: 24px;
                                            margin-right: 8px;
                                        }
                                    }
                                }
                                &__title{
                                    padding: 0 24px;   
                                }
                            }
                            &__content{
                                padding: 80px 40px;
                                iframe{
                                    background-color: $color-neutral-01;
                                    width: 100%;
                                    height: calc(100vh - 240px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}