.breadcrumb{
    @include flex($ai:center, $jc:flex-start);
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $color-primary-cherry-pie-01;
    a{
        color: $color-neutral-06;
    }
    &__item{
        @include flex($ai:center);
        + .breadcrumb__item{
            &:before{
                font-family: 'tecalis-icons';
                content: "\e912";
                font-size: 16px;
                color: $color-neutral-06;
                margin: 0 8px;
            }
        }
    }
}