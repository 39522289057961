.header{
    @include flex($ai:center, $jc:space-between);
    border-bottom: 1px solid #EAE8F0;
    padding: 16px 24px;
    &__logo{
        background-image: url('../resources/logo_new.svg');
        width: 123px;
        height: 30px;
        background-size: cover;
    }
    &__options{
        @include flex($ai:center);
        &__level-info{
            @include flex($ai:flex-start);
            cursor: pointer;
            width: 120px;
            margin-right: 64px;
            i[class^='icon-']{
                font-size: 32px;
            }
            .level-info{
                &__info{
                    @include flex-child($fg:2);
                    margin-left: 8px;
                    &__title{
                        color: $color-neutral-07;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 700;
                        margin-bottom: 4px;
                    }
                    .progress{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .button-icon,
        app-button-icon{
            padding: 0;
            &:hover{
                background-color: transparent;
            }
            + .dropdown,
            + app-dropdown{
                margin-left: 32px;
            }
        }
        .dropdown,
        app-dropdown{
            min-width: inherit;
            &[data-tooltip],
            app-button-icon[data-tooltip]{

                //Avatar
                app-avatar{
                    .avatar-group{
                        .avatar{
                            span{
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                &:before{
                    @include position ($p: absolute, $b: -84px, $l:-16px);
                }
            }
        }
    }

    //WORKSPACE
    &-workspace{
        @include flex($ai:center, $jc:space-between);
        background-color: $color-primary-cherry-pie-01;
        padding: 16px 24px;
        a{
            app-icons{
                i[class^='icon-']{
                    font-size: 24px;
                    margin-right: 8px;
                }
            }
            color: $color-neutral-01;
            &:hover{
                color: $color-neutral-01;
            }
        }
        &__title{
            @include flex($ai:center, $jc:center);
            @include flex-child($fg:2);
            h5{
                color: $color-neutral-01;
                margin-right: 16px;
            }
            .tag{
                background-color: $color-primary-purple-02;
                cursor: pointer;
                //COLORS
                &--success{
                    background-color: $color-primary-java-01;
                    color: $color-primary-cherry-pie-01;
                }
                &--error{
                    background-color: $color-primary-carnation-02;
                    color: $color-primary-cherry-pie-01;
                }
                &--neutral{
                    background-color: $color-neutral-05;
                    color: $color-primary-cherry-pie-01;
                }
                &--warning{
                    background-color: $color-accent-orange-02;
                    color: $color-primary-cherry-pie-01;
                }
            }
        }
        &__actions{
            @include flex($ai:center, $jc:flex-end);
            .button + .button,
            app-button + app-button{
                margin-left: 16px;
            }
        }
    }
}